.ProfileIcon:hover {
  opacity: 0.6;
  cursor: pointer;
}

.TopNavbar {
  z-index: 111 !important;
}

.ButtonBurger:hover em::before,
.ButtonBurger:focus em::before {
  color: #495057;
}

.NavbarItems {
  color: white;
}

.Divider {
  height: 25px;
  width: 1px;
  margin-right: 1rem;
  border: 1px solid white;
}

.TextHelpWrapper {
  color: white;
  margin-right: 1rem;
}

.TextHelpWrapper:hover {
  color: white;
  text-decoration: none;
}

.TextHelp {
  font-size: 20px !important;
}

.TextHelp:hover {
  text-decoration: underline;
}

@media (max-width: 720px) {
  .TextHelp {
    display: none;
  }
}

.ModalContent {
  background: white;
  position: relative;
  width: 717px;
  min-width: 500px;
  margin: 0 auto;
}

.HelpText {
  font-size: 1rem;
  font-weight: 600;
}

.HelpTextUnderline {
  text-decoration: underline;
}
