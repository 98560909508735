.ProfileButton:hover {
  cursor: pointer;
}
.NameText {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  color: #000000;
}

.SecondText {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;

  color: #919da8;
}

.OffsideContainer {
  margin-top: 64px !important;
}
