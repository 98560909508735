@import url(//fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,400italic);
.auth_Wrapper__2zEnu {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: stretch;
  flex-direction: row;
}

.auth_Photo__2HWD7 {
  flex-grow: 1;

  background: url(../../static/media/mainPhoto.062c3def.jpg);
  background-size: cover;
}

.auth_FormWrapper__2pPVy {
  background: #f36330;

  width: 36.25rem;
  height: 100%;

  padding: 0.625rem 2.25rem 0 2.25rem;
}

.auth_Logo__2G0Ge {
  display: block;

  margin: 0 auto 2.375rem auto;
}

.auth_HeaderText__H0HyC {
  margin: 0 0 1.1875rem 0;
}

.auth_HeaderText__H0HyC p {
  color: #ffffff;

  font-weight: 600;
  font-size: 1.75rem;
  line-height: 1.875rem;

  text-align: center;

  padding: 0;
  margin: 0;
}

.auth_FormHeader__5xwk8 {
  font-weight: 400;
  font-size: 1.5rem;

  text-align: center;

  color: #929292;

  margin: 0 0 0.25rem 0;
}

.auth_Description__3e-Uw {
  text-align: center;

  font-size: 1.125rem;

  margin: 0 0 1.3125rem 0;
}

input.auth_Input__u_ZJr {
  font-size: 1.125rem;
  height: 2.5rem;

  margin: 0 0 1.125rem 0 !important;
}

.auth_CardWrapper__3ndSz {
  margin: 0 0 2rem 0;
}

div.auth_Card__3q5Qn {
  background: #ffffff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.4);
  border-radius: 10px;

  border: 0;
}

div.auth_CardBody__3sPyj {
  padding: 1.5rem;
}

.auth_SignUpWrapper__3oZQY,
.auth_SubmitWrapper__2Cze1 {
  display: flex;
}

.auth_SubmitWrapper__2Cze1 {
  align-items: center;
}

.auth_SignUpWrapper__3oZQY div,
.auth_SubmitWrapper__2Cze1 div {
  flex-grow: 1;
  flex-basis: 50%;
}

.auth_SignUpWrapper__3oZQY .auth_Hint__2TOkF,
.auth_SignUpWrapper__3oZQY .auth_CreateAccountLink__2SB5s {
  font-size: 1.125rem;
}

.auth_SignUpWrapper__3oZQY .auth_CreateAccountLink__2SB5s {
  color: #5d9cec;
  font-weight: bold;
  text-align: right;
}

.auth_SignUpWrapper__3oZQY .auth_CreateAccountLink__2SB5s:hover {
  cursor: pointer;

  text-decoration: underline;
}

.auth_SubmitButtonWrapper__3yJbV {
  text-align: right;
}

div.auth_Footer__DG7TW {
  padding: 1rem 1.5rem;
}

button.auth_Button__2JUE3 {
  font-size: 0.875rem;
  font-weight: bold;
}

.auth_CopyRight__3e53I {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;

  text-align: center;
}

.footer_Alert__2hgpH {
  width: 100%;
}

.footer_Card__2etyn {
  margin-bottom: 0 !important;
}

.footer_ButtonPrimary__krD0S,
.footer_ButtonSecondary__38Wpy {
  width: 135px;
  height: 32px;
  left: 795px;
  top: 296px;

  border-radius: 5px;
}

.footer_ButtonSecondary__38Wpy {
  background-color: #efefef !important;
  color: black;
}

.footer_ButtonPrimary__krD0S {
  margin-left: 22px !important;
}

.modalDialog_Dialog__2YZJY {
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Header_ProfileIcon__3vtP4:hover {
  opacity: 0.6;
  cursor: pointer;
}

.Header_TopNavbar__UDGkV {
  z-index: 111 !important;
}

.Header_ButtonBurger__Elph5:hover em::before,
.Header_ButtonBurger__Elph5:focus em::before {
  color: #495057;
}

.Header_NavbarItems__3nKb6 {
  color: white;
}

.Header_Divider__1Ru4Z {
  height: 25px;
  width: 1px;
  margin-right: 1rem;
  border: 1px solid white;
}

.Header_TextHelpWrapper__3bUMq {
  color: white;
  margin-right: 1rem;
}

.Header_TextHelpWrapper__3bUMq:hover {
  color: white;
  text-decoration: none;
}

.Header_TextHelp__3gTLq {
  font-size: 20px !important;
}

.Header_TextHelp__3gTLq:hover {
  text-decoration: underline;
}

@media (max-width: 720px) {
  .Header_TextHelp__3gTLq {
    display: none;
  }
}

.Header_ModalContent__1q_Bb {
  background: white;
  position: relative;
  width: 717px;
  min-width: 500px;
  margin: 0 auto;
}

.Header_HelpText___QGlq {
  font-size: 1rem;
  font-weight: 600;
}

.Header_HelpTextUnderline__2uU-b {
  text-decoration: underline;
}

.Sidebar_FestTile__k-C5A {
  margin: 0.75rem 0 0.5rem 0;
  padding: 0 0 1rem 0;

  text-align: center;

  border-bottom: 1px solid #d9d9d9;
}

.Sidebar_FestTile__k-C5A img {
  width: 55%;
}

.Sidebar_FestTile__k-C5A .Sidebar_FestName__2ET4_ {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.4375rem;

  color: #5d9cec;
}

.Sidebar_AsideContainer__3F_eK {
  z-index: 110 !important;
}

.Offsidebar_ProfileButton__71pGX:hover {
  cursor: pointer;
}
.Offsidebar_NameText__3ZOsT {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  /* identical to box height */

  color: #000000;
}

.Offsidebar_SecondText__3VWm2 {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;

  color: #919da8;
}

.Offsidebar_OffsideContainer__3SIge {
  margin-top: 64px !important;
}

.login_Wrapper__2E8Aw {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: stretch;
  flex-direction: row;
}

.login_FormHeader__VP3G6 {
  font-weight: 400;
  font-size: 1.5rem;

  text-align: center;

  color: #929292;

  margin: 0 0 0.25rem 0;
}

.login_Description__1m5RU {
  text-align: center;

  font-size: 1.125rem;

  margin: 0 0 1.3125rem 0;
}

input.login_Input__b2Luf {
  font-size: 1.125rem;
  height: 2.5rem;

  margin: 0 0 1.125rem 0 !important;
}

div.login_Card__oqvY7 {
  background: #ffffff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.4);
  border-radius: 10px;

  border: 0;
}

div.login_CardBody__3pfoM {
  padding: 1.5rem;
}

.login_SignUpWrapper__9nTXh,
.login_SubmitWrapper__Aafvr {
  display: flex;
}

.login_SubmitWrapper__Aafvr {
  align-items: center;
}

.login_SignUpWrapper__9nTXh div,
.login_SubmitWrapper__Aafvr div {
  flex-grow: 1;
  flex-basis: 50%;
}

.login_SignUpWrapper__9nTXh .login_Hint__IOMQe,
.login_SignUpWrapper__9nTXh .login_CreateAccountLink__2yBPB {
  font-size: 1.125rem;
}

.login_SignUpWrapper__9nTXh .login_CreateAccountLink__2yBPB {
  color: #5d9cec;
  font-weight: bold;
  text-align: right;
}

.login_SignUpWrapper__9nTXh .login_CreateAccountLink__2yBPB:hover {
  cursor: pointer;

  text-decoration: underline;
}

.login_SubmitButtonWrapper__3paqc {
  text-align: right;
}

div.login_Footer__2X5ec {
  padding: 1rem 1.5rem;
}

button.login_Button__12FWV {
  font-size: 0.875rem;
  font-weight: bold;
}

.login_CopyRight__Qeyzm {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;

  text-align: center;
}

.login_TextButton__2gB3d {
  color: #495057;
}

.login_TextButton__2gB3d:hover {
  cursor: pointer;
  opacity: 0.6;

  color: #495057;
  text-decoration: none;
}

.register_Wrapper__1DkT6 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: stretch;
  flex-direction: row;
}

.register_FormHeader__3yUeX {
  font-weight: 400;
  font-size: 1.5rem;

  text-align: center;

  color: #929292;

  margin: 0 0 0.25rem 0;
}

.register_Description__6_8bF {
  text-align: center;

  font-size: 1.125rem;

  margin: 0 0 1.3125rem 0;
}

input.register_Input__3a_m8 {
  font-size: 1.125rem;
  height: 2.5rem;

  margin: 0 0 1.125rem 0 !important;
}

div.register_Card__1pWrw {
  background: #ffffff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.4);
  border-radius: 10px;

  border: 0;
}

div.register_CardBody__2ErMo {
  padding: 1.5rem;
}

.register_SignUpWrapper__20YXF,
.register_SubmitWrapper__2gQnu {
  display: flex;
}

.register_SubmitWrapper__2gQnu {
  align-items: center;
}

.register_SignUpWrapper__20YXF div,
.register_SubmitWrapper__2gQnu div {
  flex-grow: 1;
  flex-basis: 50%;
}

.register_SignUpWrapper__20YXF .register_Hint__1bkcw,
.register_SignUpWrapper__20YXF .register_CreateAccountLink__2yG5j {
  font-size: 1.125rem;
}

.register_SignUpWrapper__20YXF .register_CreateAccountLink__2yG5j {
  color: #5d9cec;
  font-weight: bold;
  text-align: right;
}

.register_SignUpWrapper__20YXF .register_CreateAccountLink__2yG5j:hover {
  cursor: pointer;

  text-decoration: underline;
}

.register_SubmitButtonWrapper__3PkKu {
  text-align: right;
}

div.register_Footer__umxuX {
  padding: 1rem 1.5rem;
}

button.register_Button__1V5EE {
  font-size: 0.875rem;
  font-weight: bold;
}

.register_CopyRight__1MGXI {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;

  text-align: center;
}

.register_TextButton__2h7HK {
  color: #495057;
}

.register_TextButton__2h7HK:hover {
  cursor: pointer;
  opacity: 0.6;

  color: #495057;
  text-decoration: none;
}

.register_Check__3GlJP {
  border-color: #f05050 !important;
}

.dates_Date__2zTCS,
.dates_Month__38ktw {
  font-weight: 600;
  color: #656565;
}

.dates_Date__2zTCS {
  font-size: 2.25rem;
  line-height: 2.81rem;
}

.dates_Month__38ktw {
  font-size: 1.25rem;
  line-height: 1.56rem;
}

.dates_Inactive__3B0pB {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.4375rem;
}

.itemActive_Name__2oljE,
.itemActive_Description__1uU-y {
  font-weight: 600;
}

.itemActive_Name__2oljE {
  font-size: 1.125rem;
  line-height: 1.43rem;

  text-transform: uppercase;

  margin: 0.375rem 0 0 0;
}

.itemActive_Name__2oljE a:hover {
  color: #5d9cec;
}

.itemActive_Description__1uU-y {
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 0.93rem;

  color: #656565;
}

.itemActive_Image__2hTYF {
  max-width: 100%;
}

.list_ContentWrapper__1btp8 {
  border-top: none !important;
  margin-top: 0 !important;
}

.itemArchive_TextCell__2zsI8 {
  padding: 0 2rem !important;
  vertical-align: middle !important;
}

.itemArchive_Image__21ncH {
  max-width: 100%;
}

.itemArchive_ImageCell__fD_9y {
  max-width: 5rem;
}

.itemArchive_Name__2mMM6 {
  font-weight: 600;
  font-size: 1.375rem;
}

.itemArchive_Name__2mMM6 a:hover {
  color: #5d9cec;
}

.listArchive_Count__KAUkR {
  line-height: 1.25rem;

  font-weight: 600;
  color: #656565;
}

.listArchive_ContentWrapper__y-UFz {
  border-top: none !important;
  margin-top: 0 !important;
}

.typegroup_List__2a9pe {
  padding: 0 0 0 0.9375rem;
}

.typegroup_CardHeader__2mm8_ {
  padding-bottom: 0 !important;
}

.typegroup_Header__18OV3 {
  margin: 0;

  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.56rem;
}

.typegroup_Link__2Ku9P {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.4375rem;

  margin: 0 0 0.5rem 0.75rem;

  position: relative;
  display: block;
}

.typegroup_Link__2Ku9P::after {
  position: absolute;
  top: 0.6rem;
  left: -0.75rem;

  background-color: #656565;

  content: "";
  display: block;

  width: 6px;
  height: 6px;

  border-radius: 100px;
}

.typegroup_Expired__3LG5f,
.typegroup_Expired__3LG5f:hover {
  pointer-events: none;
  color: #929292;
}

.typegroup_Expired__3LG5f .typegroup_Description__2k49u {
  font-size: 0.875rem;

  line-height: 1rem;
}

.typegroup_Subtitle__3orDj {
  font-size: 0.75rem;
  font-weight: 600;
  color: #929292;
}

.typegroup_CardWrapper__1PtLJ {
  height: 100%;
}

.typegroupslist_Warning__1m1y1 {
  font-size: 1rem;
  line-height: 2.5rem;
}

.typegroupslist_Alert__2KMDl {
  background-color: #f36330 !important;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  height: 51px;
  width: 74px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.typegroupslist_Alertwrapper__1-a_S {
  display: flex;
  align-items: center;
  background: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 1rem;
}

.typegroupslist_ContentWrapper__1yLoX {
  border-top: none !important;
  margin-top: 0 !important;
}

input.Rules_Input__27hhE {
  font-size: 1.125rem;
  height: 2.5rem;

  margin: 0 0 1.125rem 0 !important;
}

.Rules_SubmitWrapper__vm0L8 {
  display: flex;
}

.Rules_SubmitWrapper__vm0L8 {
  align-items: center;
}

.Rules_CheckboxWrapper__3SNyD {
  display: block;
  float: right;
  margin-bottom: 0;
  font-weight: 600;
}

.Rules_SubmitWrapper__vm0L8 div {
  flex-grow: 1;
  flex-basis: 50%;
}

.Rules_SubmitButtonWrapper__1qlqO {
  text-align: right;
}

div.Rules_Footer__2CQr8 {
  padding: 1rem 1.5rem;
}

button.Rules_Button__17rou {
  font-size: 0.875rem;
  font-weight: bold;
}

.Rules_CheckRules__3UrKN {
  border-top: 1px solid #cfdbe2 !important;
}

.Rules_Warning__1Bz6F {
  font-size: 1rem;
  line-height: 2.5rem;
}

.Rules_TextButton__3pFLW:hover {
  cursor: pointer;
  opacity: 0.6;
}

.Rules_ContentWrapper__WFLUe {
  border-top: none !important;
  margin-top: 0 !important;
}

.list_CardHeaderGray__2-fpA {
  background-color: #f7f7f7 !important;
}

.list_TableFilters__3qUaP {
  display: flex;
}

.list_TableFilters__3qUaP > div {
  flex-basis: 0;
  flex-grow: 1;
  flex-shrink: 1;

  padding: 0 0.5rem;
}

.list_Table__1PPfk thead tr th {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.list_WaitForCheck__1PCka.list_Odd__2f3YX td {
  /* background-color: #fffcdc !important; */
  background-color: #fff9c0 !important;
}

.list_WaitForCheck__1PCka td {
  /* background-color: #fffeec !important; */
  background-color: #fffbd3 !important;
}

.list_Checked__3eZE0.list_Odd__2f3YX td {
  /* background-color: #dcffdc !important; */
  background-color: #c2ffc2 !important;
}

.list_Checked__3eZE0 td {
  /* background-color: #ecffec !important; */
  background-color: #d5ffd5 !important;
}

.list_NeedToCorrect__39Lo7.list_Odd__2f3YX td {
  /* background-color: #ffdcdc !important; */
  background-color: #ffd2d2 !important;
}

.list_NeedToCorrect__39Lo7 td {
  /* background-color: #ffecec !important; */
  background-color: #ffe0e0 !important;
}

.list_Row__384kP td {
  text-align: center;

  border: 1px solid #eeeeee;

  vertical-align: middle !important;
}

.list_Row__384kP td,
.list_Row__384kP td div {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;

  color: #656565;
}

.list_Row__384kP td div.list_Date__eFdU4 {
  color: #929292;
}

.list_Row__384kP td.list_Number__2mYx4 {
  font-size: 1.125rem;
  line-height: 1.4375rem;

  color: #656565;
}

.list_Row__384kP td.list_Name__2y_GW {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  color: #5d9cec;

  text-align: left;
}

.list_Row__384kP td.list_Name__2y_GW .list_Inactive__sOD9S {
  color: #656565;
}

.list_Row__384kP .list_TownNames__2MCha {
  font-size: 12px;
  color: #929292;
}

.list_Row__384kP .list_UnderageIndicator__1t5uT {
  max-width: 2.5rem;
}

.list_Row__384kP .list_ExtraFields__Wwc6I > div {
  color: #656565;
  font-size: 12px;
}

.list_Type__fbhGW {
  max-width: 7.25rem;
}

.list_State__tgR9G {
  max-width: 7.75rem;
}

.list_StateText__2zIFv {
  word-wrap: break-word;

  display: inline-block;
}

.list_Review__1xIYT {
  white-space: nowrap;
}

.list_Count__1YfWD {
  line-height: 1.25rem;

  font-weight: 600;
  color: #656565;
}

.list_TableFilters__3qUaP input,
.list_FilterText__1eHFB {
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;

  color: #a3b3bc;
}

.list_FilterText__1eHFB {
  display: inline-block;
  width: 100%;

  text-align: left;
}

.list_TableFilters__3qUaP button {
  font-weight: 600;
  font-size: 1rem;
}

.list_FilterButton__2hzDG {
  position: relative;
}

.list_Caret__23ZKH {
  height: 100%;
  border-left: 1px solid #dde6e9;

  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  width: 2.375rem;

  z-index: 100;
  pointer-events: none;
}

.list_Caret__23ZKH::before {
  content: "";
  right: 0.85rem;

  top: 1rem;
  position: absolute;

  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;

  border-top: 5px solid #a3b3bc;

  z-index: 100;
}

.list_BgaWhite__tZgyw {
  background-color: #fff !important;
}

.list_ContentWrapper__1B9Ed {
  border-top: none !important;
  margin-top: 0 !important;
}

.reset_Wrapper__16EY0 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: stretch;
  flex-direction: row;
}

.reset_FormHeader__3cP77 {
  font-weight: 400;
  font-size: 1.5rem;

  text-align: center;

  color: #929292;

  margin: 0 0 0.25rem 0;
}

.reset_Description__16XZz {
  text-align: center;

  font-size: 1.125rem;

  margin: 0 0 1.3125rem 0;
}

input.reset_Input__4AKJI {
  font-size: 1.125rem;
  height: 2.5rem;

  margin: 0 0 1.125rem 0 !important;
}

div.reset_Card__2X-kb {
  background: #ffffff;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.4);
  border-radius: 10px;

  border: 0;
}

div.reset_CardBody__34nsu {
  padding: 1.5rem;
}

.reset_SignUpWrapper__34FYv,
.reset_SubmitWrapper__CtAFH {
  display: flex;
}

.reset_SubmitWrapper__CtAFH {
  align-items: center;
}

.reset_SignUpWrapper__34FYv div,
.reset_SubmitWrapper__CtAFH div {
  flex-grow: 1;
  flex-basis: 50%;
}

.reset_SignUpWrapper__34FYv .reset_Hint__3NWWa,
.reset_SignUpWrapper__34FYv .reset_CreateAccountLink__17I5W {
  font-size: 1.125rem;
}

.reset_SignUpWrapper__34FYv .reset_CreateAccountLink__17I5W {
  color: #5d9cec;
  font-weight: bold;
  text-align: right;
}

.reset_SignUpWrapper__34FYv .reset_CreateAccountLink__17I5W:hover {
  cursor: pointer;

  text-decoration: underline;
}

.reset_SubmitButtonWrapper__3u4TT {
  text-align: right;
}

div.reset_Footer__5rUNS {
  padding: 1rem 1.5rem;
}

button.reset_Button__WKFPE {
  font-size: 0.875rem;
  font-weight: bold;
}

.reset_CopyRight__wyORD {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;

  text-align: center;
}

.reset_TextButton__3kcjd {
  color: #495057;
}

.reset_TextButton__3kcjd:hover {
  cursor: pointer;
  opacity: 0.6;

  color: #495057;
  text-decoration: none;
}

.footer_Alert__3ZK-v {
  width: 100%;
}

.footer_Card__2nJtK {
  margin-bottom: 0 !important;
}

.footer_ButtonPrimary__2wtnV,
.footer_ButtonSecondary__2dHvZ {
  width: 135px;
  height: 32px;
  left: 795px;
  top: 296px;

  border-radius: 5px;
}

.footer_ButtonSecondary__2dHvZ {
  background-color: #efefef !important;
  color: black;
}

.footer_ButtonPrimary__2wtnV {
  margin-left: 22px !important;
}

.requestFilesModal_SelectFileType__3kpj1,
.requestFilesModal_SeletFileTypeHeader__1ulBj {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;

  color: #212529;
}

.requestFilesModal_CheckboxLabel__28ub0 {
  margin: 0;
}

.requestFilesModal_FileName__2odLU {
  margin-top: 0.5rem;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  color: #5d9cec;
}

.requestFilesModal_FileName__2odLU:hover {
  text-decoration: underline;
}

.requestFilesModal_TextRed__3-MtT {
  color: red;
}

.requestFilesModal_Valid__2jwVK {
  border-color: #27c24c;
}

.requestFilesModal_Invalid__AxXzH {
  border-color: red;
}

.requestFilesModal_ModalContent__1o2e7 {
  position: relative;
  width: 717px;
  min-width: 500px;
  margin: 0 auto;
}

.requestFilesModal_IconFile__1BWxY {
  text-align: center;
  margin-top: 0.5rem;
}

.requestFiles_ListGroupItem__M3lgY {
  cursor: pointer;
}

.requestFiles_Dropzone__3spl_ {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;

  padding: 7px 11px 7px 12px !important;

  color: #555555;

  box-shadow: transparent !important;
}

.requestFiles_DropzoneWrapper__ko2U4 {
  box-shadow: none !important;
}

.requestFiles_DropzoneHint__3qSDW {
  display: flex;
  justify-content: space-between;
  color: #909fa7;
}

.requestFiles_SelectFileType__3kiLB,
.requestFiles_SeletFileTypeHeader__cggn_ {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;

  color: #212529;
}

.requestFiles_SelectFileTypeWrapper__1WlGI {
  margin-bottom: 9px;
}

.requestFiles_SeletFileTypeHeader__cggn_ {
  font-weight: bold;
  color: #515253;
  margin-bottom: 10px;
}

.requestFiles_InputText__2G9aO {
  display: flex;
  justify-content: space-between;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;

  color: #212529;
}

.requestFiles_IntersectionsCollapse__2icNP {
  line-height: 2.8125rem;

  font-weight: bold;
  font-size: 1rem;

  text-align: left;
}

.requestFiles_IntersectionsCollapse__2icNP .requestFiles_Label__3ecJi {
  color: #5d9cec;
}

.requestFiles_IntersectionsCollapse__2icNP .requestFiles_Underline__MAFTL {
  text-decoration: underline;
}

.requestFiles_IntersectionsCollapse__2icNP .requestFiles_Label__3ecJi:hover {
  cursor: pointer;
}

.requestFiles_FileName__QRNQo {
  margin-top: 0.5rem;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  color: #5d9cec;
}

.requestFiles_FileName__QRNQo:hover {
  text-decoration: underline;
}

.commentsPart_CommentRow__3tIY9 {
  width: 100%;
  margin: 0 !important;
}

.commentsPart_CommentDuringTime__TWkNY {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  margin-left: 5px;
  color: #929292;
}

.commentsPart_CuratorBackground__2Z0LC {
  background-color: #686868 !important;
}

.commentsPart_AnnounceBackground__2qIll {
  background-color: #ff7272 !important;
}

.commentsPart_CommentBody__2p7Yu {
  font-size: 1.4rem;
}

.commentEditor_CommentEditorTextCurator__1re_4,
.commentEditor_CommentEditorTextUser__1pd11 {
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
}

.commentEditor_CommentEditorTextCurator__1re_4 {
  color: #ebebeb;
}
.commentEditor_CommentEditorTextUser__1pd11 {
  color: #5d9cec;
}

.commentEditor_CommentEditorText__2IgGN {
  font-size: 18px;
  line-height: 23px;

  -webkit-text-decoration-line: underline;

          text-decoration-line: underline;
}

.commentEditor_CommentEditorLabel__3LqzM {
  cursor: pointer;
}

.commentEditor_CommentEditorLabel__3LqzM:hover {
  opacity: 0.5;
}

.commentEditor_CommentEditorCurator__2b3Js {
  background-color: #686868 !important;

  border-radius: 0 !important;
}

.commentEditor_CommentEditorUser__1E9bv {
  background-color: white !important;

  border-radius: 0 !important;
}

.commentEditor_ParticipantButtonWrapper__JZiBT {
  text-align: right;
  padding: 0.5rem;

  background: #f7f7f7;
  border: 1px solid #cfdbe2;

  margin: 0;
}

.commentEditor_ParticipantButtonWrapper__JZiBT button {
  font-weight: 600;
  font-size: 0.75rem;

  padding: 0.3125rem 1.25rem;
}

.commentEditor_CommentEditorDropDown__2AkK4 {
  margin-right: 0.5rem;
}

.commentEditor_Wrapper__Ci62q {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

div.commentEditor_ReviewMenu__2U4pJ {
  padding: 0;
}

div.commentEditor_ReviewMenu__2U4pJ button,
div.commentEditor_ReviewMenu__2U4pJ button:focus {
  line-height: 1.875rem !important;
  font-weight: 600;

  border-bottom: 1px solid #dde6e9;

  padding-left: 1rem !important;

  color: #212529;
}

div.commentEditor_ReviewMenu__2U4pJ button em {
  padding: 0 0.25rem 0 0;
}

.commentEditor_BgNeedToCorrect__2Ubu5,
button.commentEditor_BgNeedToCorrect__2Ubu5,
button.commentEditor_BgNeedToCorrect__2Ubu5:hover {
  background-color: #ffecec;
}

em.commentEditor_Icon__30Anh {
  color: #656565 !important;

  font-size: 1.125rem;
}

.commentEditor_ButtonDropDrown__1L2E7 {
  padding: 0.375rem 0.5rem !important;
}

.field_Wrapper__w9jUV {
  padding: 0.25rem;
  margin: 0 0 0.2rem 0;
}

.field_Wrapper__w9jUV.field_Bordered__2gl9G {
  border: 1px dotted #cfdbe2;
}

.field_Wrapper__w9jUV input,
.field_Wrapper__w9jUV textarea,
.field_Wrapper__w9jUV select,
.field_Wrapper__w9jUV .field_Checkbox__3krHO label {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: bold;
}

.field_FieldName__1kIMe {
  width: 10rem;

  text-align: right;
  line-height: 1rem;

  padding: 0 1rem 0 0;

  flex-grow: 0;
  flex-shrink: 0;
}

.field_FieldWrapper__24Qt4 {
  flex-grow: 1;
  padding: 0 1rem 0 0;
}

.field_OnlyNameField__2R47e {
  text-align: center;
  line-height: 2rem;
  font-weight: bold;
  font-size: 1rem;
}

.field_WithFix__22vsd > div > div {
  display: block !important;
}

.field_NoGap__3Zzdg {
  border-bottom: 0;
  margin-bottom: 0;
}

.field_Hint__1sv4T {
  font-size: 0.76rem;
  line-height: 1rem;

  color: #929292;
}

.field_Hint__1sv4T p,
.field_Wrapper__w9jUV textarea {
  margin: 0 !important;
}

.field_Checkbox__3krHO {
  display: inline-block;

  padding: 0 1rem 0 0;

  margin: 0 0 0.25rem 0;
}

.field_SubElWrapper__3TAnA {
  margin: 0.5rem 0 0 0;
  padding: 0.5rem;

  border-top: 1px dotted #cfdbe2;
}

.field_DurationWrapper__YRthD {
  display: flex;
}

.field_DurationWrapper__YRthD .field_Duration__3ZoVk {
  width: 4.5rem;
}

.field_DurationWrapper__YRthD .field_Duration__3ZoVk input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  padding-right: 0.5rem;

  /* border-radius: 0; */
}

.field_DurationWrapper__YRthD .field_Note__bdfol {
  flex-grow: 1;
}

.field_DurationWrapper__YRthD .field_Note__bdfol input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0;
}

.field_BgWaitForCheck__3bTGa,
button.field_BgWaitForCheck__3bTGa,
button.field_BgWaitForCheck__3bTGa:hover {
  background-color: #fffeec;
}

.field_BgChecked__3W1RJ,
button.field_BgChecked__3W1RJ,
button.field_BgChecked__3W1RJ:hover {
  background-color: #ecffec;
}

.field_BgNeedToCorrect__3OQq1,
button.field_BgNeedToCorrect__3OQq1,
button.field_BgNeedToCorrect__3OQq1:hover {
  background-color: #ffecec;
}

em.field_Icon__ViBON {
  color: #656565 !important;

  font-size: 1.125rem;
}

.field_Controls__1Fu0N {
  display: flex;
  align-items: center;

  width: 6rem;

  flex-shrink: 0;
  flex-grow: 0;

  flex-direction: row;
}

.field_Controls__1Fu0N button {
  padding: 0.375rem 0.5rem;

  margin: 0 0.25rem 0 0;
}

.field_Controls__1Fu0N .field_IsEditedContainer__2FLcU {
  margin-left: 0.5rem;
}

div.field_ReviewMenu__3_v70 {
  padding: 0;
}

div.field_ReviewMenu__3_v70 button,
div.field_ReviewMenu__3_v70 button:focus {
  line-height: 1.875rem !important;
  font-weight: 600;

  border-bottom: 1px solid #dde6e9;

  padding-left: 1rem !important;

  color: #212529;
}

div.field_ReviewMenu__3_v70 button em {
  padding: 0 0.25rem 0 0;
}

@media (max-width: 767.98px) {
  .field_Wrapper__w9jUV > div {
    flex-direction: column;
  }

  .field_FieldName__1kIMe {
    text-align: left;

    margin: 0 0 0.25rem 0;
  }

  .field_FieldName__1kIMe,
  .field_FieldWrapper__24Qt4,
  .field_Controls__1Fu0N {
    width: 100%;

    flex-grow: unset;
    flex-shrink: unset;
  }
}

.field_Suggest__32wPC {
  line-height: 2.25rem;
  padding: 0 1rem;

  font-weight: 600;

  border: 1px solid #cecece;
  border-top: none;
}

.field_CuratorBackground__1OaJg {
  background-color: #686868 !important;
}

.field_CuratorBackground__1OaJg .field_FieldName__1kIMe {
  color: #ebebeb;
}

.styles_Wrapper__1qAlD {
  font-size: 1rem;

  padding: 0.3125rem 1.25rem 0.625rem 1.25rem;
}

.styles_Header__sIh1F {
  font-weight: bold;
}

.styles_Name__tpykJ {
  color: #5d9cec;
}

.styles_Wrapper__1qAlD ul {
  -webkit-padding-start: 1rem;
          padding-inline-start: 1rem;

  font-weight: bold;

  margin: 0;
}

.styles_ParticipantInfo__2g5T_ {
  font-weight: 400;
  font-size: 0.625rem;
}

.styles_Hr__2Omkv {
  height: 1px;
  border-bottom: 1px dotted #cfdbe2;
  margin-top: 0.625rem;
}
.containerModal_SelectFileType__1j9p9,
.containerModal_SeletFileTypeHeader__3hGA8 {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;

  color: #212529;
}

.containerModal_CheckboxLabel__3EwkI {
  margin: 0;
}

.containerModal_FileName__3PIB8 {
  margin-top: 0.5rem;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  color: #5d9cec;
}

.containerModal_FileName__3PIB8:hover {
  text-decoration: underline;
}

.containerModal_TextRed__2ciIg {
  color: red;
}

.containerModal_Valid__kOY3j {
  border-color: #27c24c;
}

.containerModal_Invalid__2XHgK {
  border-color: red;
}

.containerModal_ModalContent__2FwE0 {
  position: relative;
  width: 717px;
  min-width: 500px;
  margin: 0 auto;
}

.containerModal_IconFile__3hbMw {
  text-align: center;
  margin-top: 0.5rem;
}

.containerModal_TextButton__2Pmt9 {
  color: #5d9cec;
  cursor: pointer;
}

.containerModal_TextButton__2Pmt9:hover {
  text-decoration: underline;
}

.containerModal_ContainerModalHint___fhsU,
.containerModal_Underline__2vPcP {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  max-width: 620px;
  color: #929292;
}

.containerModal_Icons__3FKIS {
  height: 35px !important;
  width: 35px !important;
  align-items: center;
  padding: 0.45rem 0.65rem !important;

  color: white !important;
}

.containerModal_InviteInputText__23Cfo {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold !important;
  font-size: 1rem !important;
  line-height: 20px;
  color: #212529;

  padding-top: 0.4375rem !important;
}

.containerModal_Underline__2vPcP {
  text-decoration: underline;
}

.containerModal_ContainerModalStatus__2zaWn {
  font-family: sans-serif;
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;

  color: #515253;
}

.containerModal_Disabled__2HMT6 {
  opacity: 0.5;
  pointer-events: none;
}

.container_Wrapper__-FMBl {
  background-color: #fff;

  padding: 1rem;

  border: 1px solid #cfdbe2;
}

.container_ButtonWrapper__3sSt8 {
  text-align: right;
  padding: 1rem 0 0 0;
}

.container_FieldName__3oObu {
  width: 10rem;

  text-align: right;
  line-height: 1rem;

  padding: 0 1rem 0 0;

  flex-grow: 0;
  flex-shrink: 0;
}

.container_DateWrapper__2rqIu {
  padding: 0.25rem 0 0.25rem 0.25rem;
  margin: 0 0 0.2rem 0;
}

.container_Date__S26jg {
  flex-grow: 1;
  line-height: 2.25rem;

  border: 1px solid #dde6e9;
  border-radius: 0.25rem;

  padding: 0 2rem 0 1rem;

  font-weight: bold;

  color: #929292;
}

.container_DateWrapper__2rqIu .container_User__leB5E {
  color: #656565;
}

.container_ParticipantTable__3LNoC th {
  padding: 0.25rem !important;
}

.container_ParticipantTable__3LNoC td {
  border: 1px solid #eeeeee;
  padding: 0.25rem 0.75rem !important;
  text-align: center;
  vertical-align: middle !important;
  font-size: 0.785rem;
}

.container_ParticipantTable__3LNoC .container_Name__160L7 {
  font-size: 1rem;
  color: #5d9cec;
  font-weight: 600;
  text-align: left;
  cursor: pointer;
}

.container_ParticipantTable__3LNoC .container_Name__160L7:hover {
  opacity: 0.6;
}

.container_ParticipantTable__3LNoC .container_PerformerInfo__3pe0I {
  font-size: 1rem;

  text-align: left;
}

.container_ParticipantTable__3LNoC .container_Email__12qMS {
  font-size: 0.625rem;
}

.container_ParticipantTable__3LNoC .container_OrderNumber__1Mb6F {
  font-size: 0.875rem;
}

.container_ParticipantButtonWrapper__1niCg {
  text-align: right;
  padding: 0.5rem;

  background: #f7f7f7;
  border: 1px solid #cfdbe2;

  margin: 0;
}

.container_ParticipantButtonWrapper__1niCg button {
  font-weight: 600;
  font-size: 0.75rem;

  padding: 0.3125rem 1.25rem;
}

.container_IntersectionContainer__3CFhb {
  margin: 0 0 0.2rem 0;
}

.container_IntersectionsWrapper__nJrp6 {
  border: 1px dotted #cfdbe2;
  border-top: none;

  margin: 0;
}

.container_IntersectionsCollapse__2l9rF {
  line-height: 2.8125rem;

  font-weight: bold;
  font-size: 1rem;

  text-align: center;

  border-left: 1px dotted #cfdbe2;
  border-right: 1px dotted #cfdbe2;
  border-bottom: 1px solid #cfdbe2;
}

.container_IntersectionsCollapse__2l9rF .container_Label__2AERF {
  color: #5d9cec;
}

.container_IntersectionsCollapse__2l9rF .container_Underline__3pbHa {
  text-decoration: underline;
}

.container_IntersectionsCollapse__2l9rF .container_Label__2AERF:hover {
  cursor: pointer;
}

.container_IntersectionsHeaderWrapper__1EYEZ {
  display: flex;

  align-items: center;

  padding: 0.625rem 1.25rem;

  background: #f7f7f7;
  border: 1px solid #cfdbe2;
  border-top: none;

  font-weight: 600;
  font-size: 1rem;
}

.container_IntersectionsHeaderWrapper__1EYEZ div {
  padding-right: 0.5rem;
}

.container_IntersectionsHeaderWrapper__1EYEZ .container_IntersectionType__2eiqF {
  flex-grow: 1;
}

.container_IntersectionsHeaderWrapper__1EYEZ .container_IntersectionType__2eiqF select {
  width: inherit;
  font-weight: bold;
}

.container_ButtonPrimary__-hUJK,
.container_ButtonSecondary__1FX3B {
  width: 100px;
  height: 32px;
  margin-left: 0.5rem !important;
  border-radius: 5px;
}

.container_ButtonSecondary__1FX3B {
  background-color: #efefef !important;
  color: black;
}

.container_Icons__1JUV5 {
  font-size: 1rem !important;

  padding: 3px 0px 0px 1px !important;
}

.container_TableBody__22x-S > tr > td {
  font-size: 1rem !important;
}

.container_SubmitContainer__2K4j6 {
  text-align: right;

  padding: 1rem 0.5rem;
  margin-top: 0.5rem;

  border: 1px solid #cfdbe2;

  background-color: #f8f9fa;
}

.activeHistory_CommentRow__2f75k {
  width: 100%;
  margin: 0 !important;
}

.activeHistory_CommentDuringTime__k-6SS {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */
  margin-left: 5px;
  color: #929292;
}

.activeHistory_ActivityType__kE_Z3 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;

  text-align: right;

  color: #515253;
  float: right;
}

.activeHistory_CuratorBackground__2O89E {
  background-color: #686868 !important;
}

.activeHistory_AnnounceBackground__1v6HB {
  background-color: #ff7272 !important;
}

.activeHistory_Text__1UBFl > p {
  margin-top: 9px;
  margin-bottom: 9px;
}

.activeHistory_ActivityHeader__1dFY2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.activeHistory_MessageHeader__2008i {
  color: #656565;
  font-weight: bold;
}

.activeHistory_MessageOriginalValue__3LXhZ::after {
  content: " → ";
}

.activeHistory_MessageButton__Pifui {
  background: none;
  border: none;
  color: #5d9cec;
  padding: 0;
}

.activeHistory_MessageButton__Pifui:hover {
  cursor: pointer;
  text-decoration: underline;
  opacity: 0.6;
}

.activeHistory_AddedParticipantsMessageColor__1Rbub {
  color: #0c9500;
}

.activeHistory_DeletedParticipantsMessageColor__3n2ct {
  color: #fc3a3a;
}

.rules_DeadLine__Iez5E,
.rules_DeadLineDate__1sg_h,
.rules_FestHeaderTextBold__Ak0zb {
  font-style: normal;
  font-weight: 600;

  font-size: 20px;
  line-height: 18px;
}

.rules_DeadLine__Iez5E {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: #f36330;
  font-size: 14px;
}

.rules_DeadLineDate__1sg_h {
  color: #f36330;
}

.rules_SubmitWrapper__PYqQD {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rules_TextButton__2KbkK:hover {
  cursor: pointer;
  opacity: 0.6;
}

.rules_FestInfoHeader__2Y_91 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rules_Alert__14G1A {
  background-color: #f36330 !important;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  height: 51px;
  width: 74px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.rules_Alertwrapper__3OJR1 {
  display: flex;
  align-items: center;
  background: white;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 1rem;
}

.requestWrapper_TabWrapper__zW5AC {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.requestWrapper_ActiveTab__33mpJ,
.requestWrapper_Tab__3iXaH {
  flex-grow: 1;
  text-align: center;
  cursor: pointer;
  width: 285px;
  height: 40px;
  justify-self: center;

  border: 1px solid #cfdbe2;
}

.requestWrapper_ActiveTab__33mpJ {
  background: #ffffff;
}

.requestWrapper_Tab__3iXaH {
  background: #edf1f2;
}

.requestWrapper_TabText__2xE51 {
  font-family: Source Sans Pro;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  margin-top: 10px;

  color: #515253;
}

.requestWrapper_TabContent__1PuAr {
  border: none !important;
}

.requestWrapper_BlockTab__cYO2o {
  pointer-events: none;
}

.requestWrapper_Alert__2r4LX {
  background-color: #f36330 !important;
  display: flex;
  justify-content: start;
  align-items: center;
}

.requestWrapper_AlertButtonText__1jv9H {
  cursor: pointer;
  text-decoration: underline;
}

.requestWrapper_AlertButtonText__1jv9H:hover {
  color: #495057;
}

.requestWrapper_contentHeading__1Ub43 {
  display: block !important;
}

.requestWrapper_BreadcrumbItem__2Xgk2::before {
  content: "•" !important;
}

.requestWrapper_DisplayNone__3qdkK {
  display: none;
}

.requestWrapper_ContentWrapper__BvH6Z {
  border-top: none !important;
  margin-top: 0 !important;
}

.loadingspinner_Wrapper__38hLO {
  position: absolute;
  z-index: 10000;

  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.3);
}

.loadingspinner_CubeWrapper__gH0rW {
  width: 40px;
  height: 40px;

  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
}

.formCropper_Card__1Anm3 {
  height: 368px;
}

.formCropper_ImgContainer__fvJ3M {
  max-height: 240px;
}

.uploader_Card__7zIJp {
  height: 174px;
}

.uploader_Dropzone__cJOvT:hover {
  cursor: pointer;
  opacity: 0.6;
}

.uploader_BoxShadowDisabled__2Kwvm {
  box-shadow: none !important;
}

.headerPart_Alert__2eCIq {
  width: 100%;
}

.headerPart_Card__1AQ6A {
  margin-bottom: 0 !important;
}

.headerPart_TextRed__2c4l7 {
  color: red;
}

.myProfile_Card__3l263 {
  margin-bottom: 0 !important;
}

.crewItem_FieldSet__3FEFR {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.crewItem_TextLink__3sZVE {
  color: #5d9cec;
  cursor: pointer;
}

.crewItem_Button__yfvId {
  padding: 0.375rem 0.5rem !important;
}

.myCrew_Alert__tpE7- {
  width: 100%;
}

.myCrew_Card__1SqPJ {
  margin-bottom: 0 !important;
}

.myCrew_FieldSet__1Bf5e {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.myCrew_BlockWrapper__73CNR {
  padding: 10px 20px 0 20px !important;
  background: none !important;
}

.myCrew_TextButton__3dniz {
  font-weight: bold;
  color: #5d9cec;
  cursor: pointer;
  text-align: center;
}

.myCrew_TextButton__3dniz:hover {
  opacity: 0.6;
  text-decoration: underline;
}

.myCrew_TextFooter__pklOu {
  text-align: end;
}

.leaderItem_FieldSet__Wmdbj {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.leaderItem_TextLink__vv-VW {
  color: #5d9cec;
  cursor: pointer;
}

.leaderItem_Icons__1W9qe {
  height: 35px !important;
  width: 35px !important;
  align-items: center;
  padding: 0.45rem 0.65rem !important;
  margin-left: 5px;
  color: white !important;
}

.leaderItem_Button__1jsgc {
  float: right;
  padding: 0.375rem 0.5rem !important;
  opacity: 0.8;
}

.leaderItem_Disabled__34Kc9 {
  opacity: 0.5;
  pointer-events: none;
}

.myLeaders_Alert__1bKkw {
  width: 100%;
}

.myLeaders_Card__102N2 {
  margin-bottom: 0 !important;
}

.myLeaders_FieldSet__1fzlV {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.myLeaders_BlockWrapper__2Ih5x {
  padding: 10px 20px 0 20px !important;
  background: none !important;
}

.myLeaders_TextButton__SauHl {
  font-weight: bold;
  color: #5d9cec;
  cursor: pointer;
  text-align: center;
}

.myLeaders_TextButton__SauHl:hover {
  opacity: 0.6;
  text-decoration: underline;
}

.myLeaders_TextFooter__X6ZPX {
  text-align: end;
}

.inviteItem_FieldSet__28Op5 {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.inviteItem_TextLink__2CXhz {
  color: #5d9cec;
  cursor: pointer;
}

.inviteItem_Icons__35Cj8 {
  height: 35px !important;
  width: 35px !important;
  align-items: center;
  padding: 0.45rem 0.65rem !important;
  margin-left: 5px;
  color: white !important;
}

.inviteItem_Button__15jYa {
  float: right;
  padding: 0.375rem 0.5rem !important;
  opacity: 0.8;
}

.inviteItem_Disabled__1SkFy {
  opacity: 0.5;
  pointer-events: none;
}

.myInvites_Alert__2dDEk {
  width: 100%;
}

.myInvites_Card__2YNRx {
  margin-bottom: 0 !important;
}

.myInvites_FieldSet__2U22f {
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.myInvites_BlockWrapper__3DUGt {
  padding: 10px 20px 0 20px !important;
  background: none !important;
}

.myInvites_TextButton__1QfJ1 {
  font-weight: bold;
  color: #5d9cec;
  cursor: pointer;
  text-align: center;
}

.myInvites_TextButton__1QfJ1:hover {
  opacity: 0.6;
  text-decoration: underline;
}

.myInvites_TextFooter__2r7Sq {
  text-align: end;
}

.myTeam_Tabs__2fvhw {
  width: 50%;
}

.myTeam_Alert__3mpnq {
  width: 100%;
  margin-bottom: 0 !important;
}

.myTeam_Card__3AdqT {
  margin-bottom: 0 !important;
}

.commonPart_Alert__2JS28 {
  width: 100%;
}

.commonPart_Card__1OfNr {
  margin-bottom: 0 !important;
}

.commonPart_TextRed__4ppEW {
  color: red;
}

.commonPart_Valid__3atdS {
  border-color: #27c24c !important;
}

.commonPart_Invalid__15oZJ {
  border-color: red !important;
}

.chooseCityPart_Alert__2Eadp {
  width: 100%;
}

.chooseCityPart_Card__2FRv8 {
  margin-bottom: 0 !important;
}

.chooseCityPart_TextButton__3HJyV {
  color: #5d9cec;
  cursor: pointer;
}

.chooseCityPart_TextButton__3HJyV:hover {
  text-decoration: underline;
}

.chooseCityPart_TextRed__1-YlV {
  color: red;
}

.newCityPart_Alert__3GWOY {
  width: 100%;
}

.newCityPart_Card__3Y1vZ {
  margin-bottom: 0 !important;
}

.newCityPart_TextRed__UDQwN {
  color: red;
}

.newCityPart_Valid__2yg3t > div {
  border-color: #27c24c !important;
}

.newCityPart_Invalid__kRESr > div {
  border-color: red !important;
}

.contactsPart_Alert__22Owf {
  width: 100%;
}

.contactsPart_Card__lJsag {
  margin-bottom: 0 !important;
}

.contactsPart_TextRed__3Ky4Y {
  color: red;
}

.contactsPart_Valid__1gaO5 {
  border-color: #27c24c !important;
}

.contactsPart_Invalid__2d2kD {
  border-color: red !important;
}

.info_Card__1jH6Q {
  margin-bottom: 0 !important;
}

.changePassword_TextRed__2eGvy {
  color: red;
}

.changePassword_Card__3pI5C {
  margin-bottom: 0 !important;
}

.profile_Pages__3M3VE {
  border: 1px solid #e5e5e5 !important;
}

.profile_Pages__3M3VE:hover {
  cursor: pointer;
}

.profile_Alert__1ffoP {
  width: 100%;

  background-color: #f36330 !important;

  display: flex;
  justify-self: start;
  align-items: center;
}

.profile_ContentWrapper__2_lNS {
  border-top: none !important;
  margin-top: 0 !important;
}


/*!
 * Bootstrap v4.5.0 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #7266ba;
  --pink: #f532e5;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #fad732;
  --green: #37bc9b;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #5d9cec;
  --success: #27c24c;
  --info: #23b7e5;
  --warning: #ff902b;
  --danger: #f05050;
  --inverse: #131e26;
  --primary-light: #8bb8f1;
  --primary-dark: #2f80e7;
  --success-light: #43d967;
  --success-dark: #1e983b;
  --info-light: #51c6ea;
  --info-dark: #1797be;
  --warning-light: #ffab5e;
  --warning-dark: #f77600;
  --danger-light: #f47f7f;
  --danger-dark: #ec2121;
  --green-light: #58ceb1;
  --green-dark: #2b957a;
  --pink-light: #f763eb;
  --pink-dark: #e90bd6;
  --purple-light: #9289ca;
  --purple-dark: #564aa3;
  --inverse-light: #243948;
  --inverse-dark: #020304;
  --yellow-light: #fbe164;
  --yellow-dark: #f3ca06;
  --primary: #5d9cec;
  --secondary: #fff;
  --success: #27c24c;
  --info: #23b7e5;
  --warning: #ff902b;
  --danger: #f05050;
  --light: #f8f9fa;
  --dark: #3a3f51;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.52857;
  color: #656565;
  text-align: left;
  background-color: #f5f7fa; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #5d9cec;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #1b72e2;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #909FA7;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.1; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.125rem;
  background-color: #f5f7fa;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

code {
  font-size: 87.5%;
  color: #f532e5;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #656565; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #eee; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #eee; }
  .table tbody + tbody {
    border-top: 2px solid #eee; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #eee; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #eee; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fafbfc; }

.table-hover tbody tr:hover {
  color: #656565;
  background-color: #fafbfc; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #d2e3fa; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #abccf5; }

.table-hover .table-primary:hover {
  background-color: #bbd5f7; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #bbd5f7; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: white; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: white; }

.table-hover .table-secondary:hover {
  background-color: #f2f2f2; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #f2f2f2; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3eecd; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fdfa2; }

.table-hover .table-success:hover {
  background-color: #afe8bc; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #afe8bc; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #c1ebf8; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #8ddaf1; }

.table-hover .table-info:hover {
  background-color: #aae4f5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #aae4f5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffe0c4; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffc591; }

.table-hover .table-warning:hover {
  background-color: #ffd3ab; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #ffd3ab; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #fbcece; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #f7a4a4; }

.table-hover .table-danger:hover {
  background-color: #f9b6b6; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f9b6b6; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fdfdfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fbfcfc; }

.table-hover .table-light:hover {
  background-color: #ececf6; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #ececf6; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c8c9ce; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #999ba5; }

.table-hover .table-dark:hover {
  background-color: #bbbcc2; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #bbbcc2; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: #fafbfc; }

.table-hover .table-active:hover {
  background-color: #eaeef2; }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: #eaeef2; }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #eee; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: 2.1875rem;
  padding: 0.375rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.52857;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dde6e9;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #66afe9;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(93, 156, 236, 0.25); }
  .form-control::-webkit-input-placeholder {
    color: #b7bac9;
    opacity: 1; }
  .form-control::placeholder {
    color: #b7bac9;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #edf1f2;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  -webkit-appearance: none;
          appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.52857; }

.col-form-label-lg {
  padding-top: calc(0.625rem + 1px);
  padding-bottom: calc(0.625rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.3125rem + 1px);
  padding-bottom: calc(0.3125rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.52857;
  color: #656565;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.625rem + 2px);
  padding: 0.3125rem 0.625rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1.25rem + 2px);
  padding: 0.625rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.375rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #909FA7; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #27c24c; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.52857;
  color: #fff;
  background-color: rgba(39, 194, 76, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #27c24c;
  padding-right: calc(1.52857em + 0.75rem);
  background-image: none;
  background-repeat: no-repeat;
  background-position: right calc(0.38214em + 0.1875rem) center;
  background-size: calc(0.76429em + 0.375rem) calc(0.76429em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #27c24c;
    box-shadow: 0 0 0 0.2rem rgba(39, 194, 76, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.52857em + 0.75rem);
  background-position: top calc(0.38214em + 0.1875rem) right calc(0.38214em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #27c24c;
  padding-right: calc(0.75em + 2.5625rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, none #fff no-repeat center right 2rem/calc(0.76429em + 0.375rem) calc(0.76429em + 0.375rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #27c24c;
    box-shadow: 0 0 0 0.2rem rgba(39, 194, 76, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #27c24c; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #27c24c; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #27c24c; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #43d967;
  background-color: #43d967; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(39, 194, 76, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #27c24c; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #27c24c; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #27c24c;
  box-shadow: 0 0 0 0.2rem rgba(39, 194, 76, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #f05050; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.52857;
  color: #fff;
  background-color: rgba(240, 80, 80, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #f05050;
  padding-right: calc(1.52857em + 0.75rem);
  background-image: none;
  background-repeat: no-repeat;
  background-position: right calc(0.38214em + 0.1875rem) center;
  background-size: calc(0.76429em + 0.375rem) calc(0.76429em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #f05050;
    box-shadow: 0 0 0 0.2rem rgba(240, 80, 80, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.52857em + 0.75rem);
  background-position: top calc(0.38214em + 0.1875rem) right calc(0.38214em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #f05050;
  padding-right: calc(0.75em + 2.5625rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, none #fff no-repeat center right 2rem/calc(0.76429em + 0.375rem) calc(0.76429em + 0.375rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #f05050;
    box-shadow: 0 0 0 0.2rem rgba(240, 80, 80, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #f05050; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #f05050; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #f05050; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #f47f7f;
  background-color: #f47f7f; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(240, 80, 80, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f05050; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #f05050; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #f05050;
  box-shadow: 0 0 0 0.2rem rgba(240, 80, 80, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #656565;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 1rem;
  font-size: 1rem;
  line-height: 1.52857;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #656565;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(93, 156, 236, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-primary {
  color: #fff;
  background-color: #5d9cec;
  border-color: #5d9cec; }
  .btn-primary:hover {
    color: #fff;
    background-color: #3b87e8;
    border-color: #2f80e7; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #3b87e8;
    border-color: #2f80e7;
    box-shadow: 0 0 0 0.2rem rgba(117, 171, 239, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #5d9cec;
    border-color: #5d9cec; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #2f80e7;
    border-color: #2479e5; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(117, 171, 239, 0.5); }

.btn-secondary {
  color: #212529;
  background-color: #fff;
  border-color: #fff; }
  .btn-secondary:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-success {
  color: #fff;
  background-color: #27c24c;
  border-color: #27c24c; }
  .btn-success:hover {
    color: #fff;
    background-color: #21a240;
    border-color: #1e983b; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #21a240;
    border-color: #1e983b;
    box-shadow: 0 0 0 0.2rem rgba(71, 203, 103, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #27c24c;
    border-color: #27c24c; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e983b;
    border-color: #1c8d37; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(71, 203, 103, 0.5); }

.btn-info {
  color: #fff;
  background-color: #23b7e5;
  border-color: #23b7e5; }
  .btn-info:hover {
    color: #fff;
    background-color: #18a0ca;
    border-color: #1797be; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #18a0ca;
    border-color: #1797be;
    box-shadow: 0 0 0 0.2rem rgba(68, 194, 233, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #23b7e5;
    border-color: #23b7e5; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #1797be;
    border-color: #158eb3; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(68, 194, 233, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #ff902b;
  border-color: #ff902b; }
  .btn-warning:hover {
    color: #fff;
    background-color: #ff7c05;
    border-color: #f77600; }
  .btn-warning:focus, .btn-warning.focus {
    color: #fff;
    background-color: #ff7c05;
    border-color: #f77600;
    box-shadow: 0 0 0 0.2rem rgba(222, 128, 43, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #ff902b;
    border-color: #ff902b; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #f77600;
    border-color: #ea7000; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 128, 43, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #f05050;
  border-color: #f05050; }
  .btn-danger:hover {
    color: #fff;
    background-color: #ed2d2d;
    border-color: #ec2121; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #ed2d2d;
    border-color: #ec2121;
    box-shadow: 0 0 0 0.2rem rgba(242, 106, 106, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #f05050;
    border-color: #f05050; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #ec2121;
    border-color: #eb1515; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(242, 106, 106, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #3a3f51;
  border-color: #3a3f51; }
  .btn-dark:hover {
    color: #fff;
    background-color: #2a2e3b;
    border-color: #252833; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #2a2e3b;
    border-color: #252833;
    box-shadow: 0 0 0 0.2rem rgba(88, 92, 107, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #3a3f51;
    border-color: #3a3f51; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #252833;
    border-color: #1f222c; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(88, 92, 107, 0.5); }

.btn-outline-primary {
  color: #5d9cec;
  border-color: #5d9cec; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #5d9cec;
    border-color: #5d9cec; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(93, 156, 236, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #5d9cec;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #5d9cec;
    border-color: #5d9cec; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(93, 156, 236, 0.5); }

.btn-outline-secondary {
  color: #fff;
  border-color: #fff; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-success {
  color: #27c24c;
  border-color: #27c24c; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #27c24c;
    border-color: #27c24c; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(39, 194, 76, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #27c24c;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #27c24c;
    border-color: #27c24c; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(39, 194, 76, 0.5); }

.btn-outline-info {
  color: #23b7e5;
  border-color: #23b7e5; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #23b7e5;
    border-color: #23b7e5; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(35, 183, 229, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #23b7e5;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #23b7e5;
    border-color: #23b7e5; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(35, 183, 229, 0.5); }

.btn-outline-warning {
  color: #ff902b;
  border-color: #ff902b; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #ff902b;
    border-color: #ff902b; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 144, 43, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #ff902b;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ff902b;
    border-color: #ff902b; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 144, 43, 0.5); }

.btn-outline-danger {
  color: #f05050;
  border-color: #f05050; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #f05050;
    border-color: #f05050; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 80, 80, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #f05050;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #f05050;
    border-color: #f05050; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(240, 80, 80, 0.5); }

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.btn-outline-dark {
  color: #3a3f51;
  border-color: #3a3f51; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #3a3f51;
    border-color: #3a3f51; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #3a3f51;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #3a3f51;
    border-color: #3a3f51; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.5); }

.btn-link {
  font-weight: 400;
  color: #5d9cec;
  text-decoration: none; }
  .btn-link:hover {
    color: #1b72e2;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.625rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.375rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.3125rem 0.625rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #656565;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #5d9cec; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.46875rem;
  padding-left: 0.46875rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.52857;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #dde6e9;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1.25rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.625rem 1.25rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.375rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.625rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.3125rem 0.625rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.52857rem;
  padding-left: 1.5rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.26429rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #5d9cec;
    background-color: #5d9cec; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(93, 156, 236, 0.25); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #66afe9; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #cfe2f9;
    border-color: #cfe2f9; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #6c757d; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #edf1f2; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.26429rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #adb5bd solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.26429rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.25rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #5d9cec;
  background-color: #5d9cec; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(93, 156, 236, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(93, 156, 236, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(93, 156, 236, 0.5); }

.custom-switch {
  padding-left: 2.25rem; }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.26429rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
            transform: translateX(0.75rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(93, 156, 236, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: 2.1875rem;
  padding: 0.375rem 2rem 0.375rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.52857;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px;
  border: 1px solid #dde6e9;
  border-radius: 0.25rem;
  -webkit-appearance: none;
          appearance: none; }
  .custom-select:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(93, 156, 236, 0.25); }
    .custom-select:focus::-ms-value {
      color: #495057;
      background-color: #fff; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1rem;
    background-image: none; }
  .custom-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }

.custom-select-sm {
  height: calc(1.5em + 0.625rem + 2px);
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  padding-left: 0.625rem;
  font-size: 0.875rem; }

.custom-select-lg {
  height: calc(1.5em + 1.25rem + 2px);
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 1.25rem;
  font-size: 1.25rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 2.1875rem;
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 2.1875rem;
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #66afe9;
    box-shadow: 0 0 0 0.2rem rgba(93, 156, 236, 0.25); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #edf1f2; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: 2.1875rem;
  padding: 0.375rem 1rem;
  font-weight: 400;
  line-height: 1.52857;
  color: #495057;
  background-color: #fff;
  border: 1px solid #dde6e9;
  border-radius: 0.25rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.52857em + 0.75rem);
    padding: 0.375rem 1rem;
    line-height: 1.52857;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 0.25rem 0.25rem 0; }

.custom-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
          appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #f5f7fa, 0 0 0 0.2rem rgba(93, 156, 236, 0.25); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #f5f7fa, 0 0 0 0.2rem rgba(93, 156, 236, 0.25); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #f5f7fa, 0 0 0 0.2rem rgba(93, 156, 236, 0.25); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #5d9cec;
    border: 0;
    border-radius: 1rem;
    -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: none;
            appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #fdfeff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #5d9cec;
    border: 0;
    border-radius: 1rem;
    -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #fdfeff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #5d9cec;
    border: 0;
    border-radius: 1rem;
    -ms-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        -ms-transition: none;
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #fdfeff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-item {
    margin-bottom: -1px; }
  .nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #5d9cec; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.30893rem;
  padding-bottom: 0.30893rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem; }
  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group {
    border-top: inherit;
    border-bottom: inherit; }
    .card > .list-group:first-child {
      border-top-width: 0;
      border-top-left-radius: calc(0.25rem - 1px);
      border-top-right-radius: calc(0.25rem - 1px); }
    .card > .list-group:last-child {
      border-bottom-width: 0;
      border-bottom-right-radius: calc(0.25rem - 1px);
      border-bottom-left-radius: calc(0.25rem - 1px); }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

.card-title {
  margin-bottom: 0.75rem; }

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 0.75rem 1.25rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125); }
  .card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 0.75rem 1.25rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125); }
  .card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px); }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -0.75rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px); }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px); }

.card-deck .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -15px;
    margin-left: -15px; }
    .card-deck .card {
      flex: 1 0;
      margin-right: 15px;
      margin-bottom: 0;
      margin-left: 15px; } }

.card-group > .card {
  margin-bottom: 15px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 0.75rem; }

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
            column-count: 3;
    grid-column-gap: 1.25rem;
    -webkit-column-gap: 1.25rem;
            column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #fafafa;
  border-radius: 0.25rem; }

.breadcrumb-item {
  display: flex; }
  .breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem; }
    .breadcrumb-item + .breadcrumb-item::before {
      display: inline-block;
      padding-right: 0.5rem;
      color: #c1c2c3;
      content: "/"; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline; }
  .breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none; }
  .breadcrumb-item.active {
    color: #656565; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #5d9cec;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #1b72e2;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(93, 156, 236, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #5d9cec;
  border-color: #5d9cec; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem; }

.badge-primary {
  color: #fff;
  background-color: #5d9cec; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #2f80e7; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(93, 156, 236, 0.5); }

.badge-secondary {
  color: #212529;
  background-color: #fff; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #212529;
    background-color: #e6e6e6; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.badge-success {
  color: #fff;
  background-color: #27c24c; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #1e983b; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(39, 194, 76, 0.5); }

.badge-info {
  color: #fff;
  background-color: #23b7e5; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #1797be; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(35, 183, 229, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #ff902b; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #f77600; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(255, 144, 43, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #f05050; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #ec2121; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(240, 80, 80, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f8f9fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #dae0e5; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #3a3f51; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #252833; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(58, 63, 81, 0.5); }

.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #fcfcfc;
  border-radius: 0.375rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 4rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-primary {
  color: #30517b;
  background-color: #dfebfb;
  border-color: #d2e3fa; }
  .alert-primary hr {
    border-top-color: #bbd5f7; }
  .alert-primary .alert-link {
    color: #223956; }

.alert-secondary {
  color: #858585;
  background-color: white;
  border-color: white; }
  .alert-secondary hr {
    border-top-color: #f2f2f2; }
  .alert-secondary .alert-link {
    color: #6c6c6c; }

.alert-success {
  color: #146528;
  background-color: #d4f3db;
  border-color: #c3eecd; }
  .alert-success hr {
    border-top-color: #afe8bc; }
  .alert-success .alert-link {
    color: #0c3a17; }

.alert-info {
  color: #125f77;
  background-color: #d3f1fa;
  border-color: #c1ebf8; }
  .alert-info hr {
    border-top-color: #aae4f5; }
  .alert-info .alert-link {
    color: #0b3c4b; }

.alert-warning {
  color: #854b16;
  background-color: #ffe9d5;
  border-color: #ffe0c4; }
  .alert-warning hr {
    border-top-color: #ffd3ab; }
  .alert-warning .alert-link {
    color: #59320f; }

.alert-danger {
  color: #7d2a2a;
  background-color: #fcdcdc;
  border-color: #fbcece; }
  .alert-danger hr {
    border-top-color: #f9b6b6; }
  .alert-danger .alert-link {
    color: #571d1d; }

.alert-light {
  color: #818182;
  background-color: #fefefe;
  border-color: #fdfdfe; }
  .alert-light hr {
    border-top-color: #ececf6; }
  .alert-light .alert-link {
    color: #686868; }

.alert-dark {
  color: #1e212a;
  background-color: #d8d9dc;
  border-color: #c8c9ce; }
  .alert-dark hr {
    border-top-color: #bbbcc2; }
  .alert-dark .alert-link {
    color: #090a0c; }

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

@keyframes progress-bar-stripes {
  from {
    background-position: 1rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  line-height: 0;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #5d9cec;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem; }

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
          animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      -webkit-animation: none;
              animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #656565;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid #e4eaec; }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #5d9cec;
    border-color: #5d9cec; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-primary {
  color: #30517b;
  background-color: #d2e3fa; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #30517b;
    background-color: #bbd5f7; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #30517b;
    border-color: #30517b; }

.list-group-item-secondary {
  color: #858585;
  background-color: white; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-success {
  color: #146528;
  background-color: #c3eecd; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #146528;
    background-color: #afe8bc; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #146528;
    border-color: #146528; }

.list-group-item-info {
  color: #125f77;
  background-color: #c1ebf8; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #125f77;
    background-color: #aae4f5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #125f77;
    border-color: #125f77; }

.list-group-item-warning {
  color: #854b16;
  background-color: #ffe0c4; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #854b16;
    background-color: #ffd3ab; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #854b16;
    border-color: #854b16; }

.list-group-item-danger {
  color: #7d2a2a;
  background-color: #fbcece; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #7d2a2a;
    background-color: #f9b6b6; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #7d2a2a;
    border-color: #7d2a2a; }

.list-group-item-light {
  color: #818182;
  background-color: #fdfdfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182; }

.list-group-item-dark {
  color: #1e212a;
  background-color: #c8c9ce; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #1e212a;
    background-color: #bbbcc2; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1e212a;
    border-color: #1e212a; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: -webkit-transform 0.3s ease-out;
    transition: transform 0.3s ease-out;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    -webkit-transform: translate(0, -50px);
            transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    -webkit-transform: none;
            transform: none; }
  .modal.modal-static .modal-dialog {
    -webkit-transform: scale(1.02);
            transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: -webkit-min-content;
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.375rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.52857; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom-right-radius: calc(0.375rem - 1px);
  border-bottom-left-radius: calc(0.375rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: -webkit-min-content;
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.52857;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.52857;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.375rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.375rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.375rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.375rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.375rem - 1px);
  border-top-right-radius: calc(0.375rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #656565; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
          animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0); }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
          animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #5d9cec !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #2f80e7 !important; }

.bg-secondary {
  background-color: #fff !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #e6e6e6 !important; }

.bg-success {
  background-color: #27c24c !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e983b !important; }

.bg-info {
  background-color: #23b7e5 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #1797be !important; }

.bg-warning {
  background-color: #ff902b !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f77600 !important; }

.bg-danger {
  background-color: #f05050 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ec2121 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #3a3f51 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #252833 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid rgba(0, 0, 0, 0.12) !important; }

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.12) !important; }

.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important; }

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important; }

.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.12) !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #5d9cec !important; }

.border-secondary {
  border-color: #fff !important; }

.border-success {
  border-color: #27c24c !important; }

.border-info {
  border-color: #23b7e5 !important; }

.border-warning {
  border-color: #ff902b !important; }

.border-danger {
  border-color: #f05050 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #3a3f51 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.375rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #5d9cec !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #1b72e2 !important; }

.text-secondary {
  color: #fff !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #d9d9d9 !important; }

.text-success {
  color: #27c24c !important; }

a.text-success:hover, a.text-success:focus {
  color: #1a8233 !important; }

.text-info {
  color: #23b7e5 !important; }

a.text-info:hover, a.text-info:focus {
  color: #1485a8 !important; }

.text-warning {
  color: #ff902b !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #de6a00 !important; }

.text-danger {
  color: #f05050 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #e01313 !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #3a3f51 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #1a1c24 !important; }

.text-body {
  color: #656565 !important; }

.text-muted {
  color: #909FA7 !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #eee; }
  .table .thead-dark th {
    color: inherit;
    border-color: #eee; } }

/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.7.5
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */
/* ========================================================================
     Component: layout
 ========================================================================== */
html {
  /* $replace rtl */
  direction: ltr;
  height: 100%;
  touch-action: manipulation;
  font-size: 16px; }

html,
body,
#app {
  height: 100%; }

.wrapper {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  overflow-x: hidden; }
  .wrapper .aside-container {
    position: absolute;
    width: 220px;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 116;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    background-color: #fff; }
    .wrapper .aside-container .aside-inner {
      padding-top: 55px;
      height: 100%;
      width: 220px;
      overflow: hidden; }
    .wrapper .aside-container .nav-floating {
      left: inherit;
      margin-left: 220px;
      z-index: 1110;
      min-width: 190px;
      overflow: auto; }
  .wrapper .section-container {
    position: relative;
    height: 100%;
    margin-left: 0;
    z-index: 111;
    background-color: #f5f7fa;
    margin-bottom: 60px !important; }
  .wrapper .footer-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 60px;
    border-top: 1px solid #e4eaec;
    padding: 20px;
    z-index: 109;
    font-size: 0.875rem; }
    .wrapper .footer-container p {
      margin: 0; }

.modal-open .wrapper {
  z-index: 0; }

.content-wrapper {
  padding: 15px;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: -1px; }
  .content-wrapper .unwrap {
    margin: -15px; }
    @media (min-width: 768px) {
      .content-wrapper .unwrap {
        margin: -20px; } }
  .content-wrapper .content-heading {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    line-height: 1.1;
    color: #929292;
    margin: -15px;
    margin-bottom: 20px;
    padding: 15px;
    font-weight: normal;
    background-color: #fafbfc;
    border-bottom: 1px solid #cfdbe2; }
    .content-wrapper .content-heading small {
      display: block;
      font-size: 12px;
      color: #909FA7; }
  .content-wrapper .container,
  .content-wrapper .container-fluid {
    padding-left: 0;
    padding-right: 0; }
  @media (min-width: 768px) {
    .content-wrapper {
      padding: 20px; }
      .content-wrapper .content-heading {
        margin: -20px;
        margin-bottom: 20px;
        padding: 20px; }
        .content-wrapper .content-heading button,
        .content-wrapper .content-heading .btn {
          margin: 0; } }

@media (min-width: 768px) {
  body {
    min-height: 100%; }
  .wrapper .section-container,
  .wrapper .footer-container {
    margin-left: 220px; }
  .wrapper .section-container.has-sidebar-right {
    margin-right: 240px; }
    .wrapper .section-container.has-sidebar-right + .offsidebar {
      z-index: 1; } }

@media (max-width: 767.98px) {
  .wrapper .aside-container {
    margin-left: -220px; }
  .aside-toggled .wrapper .section-container,
  .aside-toggled .wrapper .footer-container {
    margin-left: 220px; }
  .aside-toggled .wrapper .aside-container {
    margin-left: 0; }
  .csstransforms3d .wrapper {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
    .csstransforms3d .wrapper .section-container,
    .csstransforms3d .wrapper .footer-container {
      margin-left: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
    .csstransforms3d .wrapper .aside-container {
      margin-left: 0;
      -webkit-transform: translate3d(-220px, 0, 0);
              transform: translate3d(-220px, 0, 0);
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
  .csstransforms3d .aside-toggled .wrapper .section-container,
  .csstransforms3d .aside-toggled .wrapper .footer-container {
    -webkit-transform: translate3d(220px, 0, 0);
            transform: translate3d(220px, 0, 0); }
  .csstransforms3d .aside-toggled .wrapper .aside-container {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@media (max-width: 767.98px) {
  .aside-collapsed .wrapper .aside-container {
    margin-left: -70px; }
  .aside-collapsed.aside-toggled .wrapper .section-container,
  .aside-collapsed.aside-toggled .wrapper .footer-container {
    margin-left: 70px; }
  .aside-collapsed.aside-toggled .wrapper .aside-container {
    margin-left: 0; }
  .csstransforms3d .aside-collapsed .wrapper {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
    .csstransforms3d .aside-collapsed .wrapper .section-container,
    .csstransforms3d .aside-collapsed .wrapper .footer-container {
      margin-left: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
    .csstransforms3d .aside-collapsed .wrapper .aside-container {
      margin-left: 0;
      -webkit-transform: translate3d(-70px, 0, 0);
              transform: translate3d(-70px, 0, 0);
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
  .csstransforms3d .aside-collapsed.aside-toggled .wrapper .section-container,
  .csstransforms3d .aside-collapsed.aside-toggled .wrapper .footer-container {
    -webkit-transform: translate3d(70px, 0, 0);
            transform: translate3d(70px, 0, 0); }
  .csstransforms3d .aside-collapsed.aside-toggled .wrapper .aside-container {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.aside-collapsed {
  overflow-y: auto; }
  .aside-collapsed .wrapper .aside-container,
  .aside-collapsed .wrapper .aside-container .aside-inner {
    width: 70px; }
  .aside-collapsed .wrapper .aside-container .nav-floating {
    margin-left: 70px; }

@media (min-width: 768px) {
  .aside-collapsed .wrapper .section-container,
  .aside-collapsed .wrapper .footer-container {
    margin-left: 70px; } }

@media (max-width: 767.98px) {
  .aside-collapsed-text .wrapper .aside-container {
    margin-left: -90px; }
  .aside-collapsed-text.aside-toggled .wrapper .section-container,
  .aside-collapsed-text.aside-toggled .wrapper .footer-container {
    margin-left: 90px; }
  .aside-collapsed-text.aside-toggled .wrapper .aside-container {
    margin-left: 0; }
  .csstransforms3d .aside-collapsed-text .wrapper {
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden; }
    .csstransforms3d .aside-collapsed-text .wrapper .section-container,
    .csstransforms3d .aside-collapsed-text .wrapper .footer-container {
      margin-left: 0;
      -webkit-transform: translate3d(0, 0, 0);
              transform: translate3d(0, 0, 0);
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
    .csstransforms3d .aside-collapsed-text .wrapper .aside-container {
      margin-left: 0;
      -webkit-transform: translate3d(-90px, 0, 0);
              transform: translate3d(-90px, 0, 0);
      transition: -webkit-transform 0.3s ease;
      transition: transform 0.3s ease;
      transition: transform 0.3s ease, -webkit-transform 0.3s ease; }
  .csstransforms3d .aside-collapsed-text.aside-toggled .wrapper .section-container,
  .csstransforms3d .aside-collapsed-text.aside-toggled .wrapper .footer-container {
    -webkit-transform: translate3d(90px, 0, 0);
            transform: translate3d(90px, 0, 0); }
  .csstransforms3d .aside-collapsed-text.aside-toggled .wrapper .aside-container {
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.aside-collapsed-text {
  overflow-y: auto; }
  .aside-collapsed-text .wrapper .aside-container,
  .aside-collapsed-text .wrapper .aside-container .aside-inner {
    width: 90px; }
  .aside-collapsed-text .wrapper .aside-container .nav-floating {
    margin-left: 90px; }

@media (min-width: 768px) {
  .aside-collapsed-text .wrapper .section-container,
  .aside-collapsed-text .wrapper .footer-container {
    margin-left: 90px; } }

@media (max-width: 1140px) {
  .layout-fixed.aside-toggled, .layout-fixed.offsidebar-open {
    overflow-y: hidden; } }

.layout-fixed .wrapper {
  /* only applied to sidebar */ }
  .layout-fixed .wrapper .topnavbar-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 121; }
  .layout-fixed .wrapper .aside-container,
  .layout-fixed .wrapper .offsidebar {
    position: fixed; }
  .layout-fixed .wrapper .aside-container {
    /* safari fix */
    height: 1px;
    min-height: 100%;
    /* ios blanks space fix */ }
    .layout-fixed .wrapper .aside-container .aside-inner {
      position: fixed;
      top: 0;
      bottom: 0; }
  .layout-fixed .wrapper .section-container {
    margin-top: 55px; }

/* IE10+ hack: safari fix breaks ie so we need to target ie only to restore */
_:-ms-lang(x),
.layout-fixed .wrapper .aside-container .aside-inner {
  position: static !important; }

@media (min-width: 992px) {
  .layout-boxed .wrapper {
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.25); }
    .layout-boxed .wrapper .offsidebar {
      position: absolute !important; }
    .layout-boxed .wrapper .aside-container {
      left: inherit; }
    .layout-boxed .wrapper,
    .layout-boxed .wrapper .topnavbar-wrapper {
      width: 970px; }
  .layout-boxed.layout-fixed .wrapper .aside-container .aside-inner {
    left: inherit; } }

@media (min-width: 1200px) {
  .layout-boxed .wrapper,
  .layout-boxed .wrapper .topnavbar-wrapper {
    width: 1140px; } }

.sidebar-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 220px;
  z-index: 112; }
  .aside-collapsed .sidebar-backdrop {
    left: 70px; }
  .aside-collapsed-text .sidebar-backdrop {
    left: 90px; }

/* ========================================================================
   Component: layout-extra
 ========================================================================== */
.hidden-footer .wrapper .footer-container {
  display: none; }

.hidden-footer .wrapper .section-container {
  margin-bottom: 0 !important; }

.layout-fs .wrapper .section-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 60px;
  top: 55px;
  height: auto;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow: hidden; }
  .layout-fs .wrapper .section-container .content-wrapper {
    width: 100%;
    height: 100%;
    padding: 0; }
    .layout-fs .wrapper .section-container .content-wrapper > * {
      width: 100%;
      height: 100%; }

.layout-fs.hidden-footer .wrapper .section-container {
  bottom: 0; }

.layout-h .wrapper .section-container {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto; }
  .layout-h .wrapper .section-container .content-wrapper .content-heading {
    display: none; }
    .layout-h .wrapper .section-container .content-wrapper .content-heading + .breadcrumb {
      display: none; }

.layout-h .wrapper .offsidebar {
  margin-top: 55px; }

.layout-h .wrapper .footer-container {
  margin: 0; }

.layout-h.layout-fixed .wrapper .section-container,
.layout-h.layout-fixed .wrapper .offsidebar {
  margin-top: 55px; }

.aside-float .wrapper {
  box-shadow: 0 0 0 #000; }
  .aside-float .wrapper .footer-container {
    border: 0; }
  .aside-float .wrapper .aside-container {
    padding: 15px 0;
    background: transparent; }
    .aside-float .wrapper .aside-container .aside-inner {
      height: 100%;
      top: 15px;
      bottom: 15px;
      left: inherit; }
    .aside-float .wrapper .aside-container .sidebar {
      border: 1px solid #e4eaec; }
    .aside-float .wrapper .aside-container .sidebar:after {
      display: none; }
  @media (min-width: 768px) {
    .aside-float .wrapper .section-container {
      padding-left: 15px; }
    .aside-float .wrapper .footer-container {
      left: 15px; } }
  .aside-float .wrapper .content-wrapper {
    border: 0; }
    .aside-float .wrapper .content-wrapper .content-heading {
      padding-top: 30px;
      border: 0;
      background-color: transparent; }
    .aside-float .wrapper .content-wrapper .unwrap {
      margin: 0 !important; }

.aside-float.aside-toggled .wrapper .aside-container {
  transition: delay(0.05s); }
  .aside-float.aside-toggled .wrapper .aside-container .aside-inner {
    margin-left: 15px; }

.aside-float.aside-toggled .wrapper .section-container {
  padding-left: 15px; }

.aside-float.aside-toggled .wrapper .footer-container {
  left: 15px; }

@media (min-width: 768px) {
  .aside-float.aside-collapsed .topnavbar .navbar-header {
    width: 100px; }
  .aside-float.aside-collapsed-text .topnavbar .navbar-header {
    width: 120px; }
  .aside-float.layout-fs .wrapper .section-container .content-wrapper {
    padding: 0 0 0 20px; }
  .aside-float .wrapper .aside-container .aside-inner {
    margin-left: 15px; } }

@media (min-width: 992px) {
  .aside-float.layout-boxed .wrapper .aside-container .aside-inner {
    margin-left: 0; }
  .aside-float.layout-boxed .wrapper .section-container {
    padding-left: 0; } }

.aside-float.aside-toggled.layout-fs .wrapper .section-container .content-wrapper {
  padding: 0 0 0 20px; }

/* ========================================================================
   Component: layout-animation.less
 ========================================================================== */
.wrapper .aside-container {
  transition: width .2s cubic-bezier(0.35, 0, 0.25, 1), translate .2s cubic-bezier(0.35, 0, 0.25, 1); }
  @media (prefers-reduced-motion: reduce) {
    .wrapper .aside-container {
      transition: none; } }

.aside-inner,
.navbar-header,
.sidebar > .sidebar-nav > li {
  transition: width 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

.wrapper .section-container {
  transition: margin-left 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

.sidebar > .sidebar-nav .badge {
  -webkit-animation: fadeInRight 1s;
          animation: fadeInRight 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both; }

.aside-collapsed .sidebar > .sidebar-nav .badge,
.aside-collapsed-text .sidebar > .sidebar-nav .badge {
  -webkit-animation: fadeIn 1s;
          animation: fadeIn 1s; }

.sidebar .sidebar-nav > li > a {
  -webkit-animation: fadeInLeft .5s;
          animation: fadeInLeft .5s; }

.sidebar > .sidebar-nav > .nav-heading,
.sidebar > .sidebar-nav > li > a > span,
.navbar-brand .brand-logo {
  -webkit-animation: fadeIn 1s;
          animation: fadeIn 1s; }

.sidebar li > a,
.sidebar li > .nav-item,
.sidebar > .sidebar-nav > .nav-heading {
  white-space: nowrap; }

.aside-collapsed .user-block-picture,
.aside-collapsed-text .user-block-picture {
  transition: width 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

.aside-collapsed .user-block,
.aside-collapsed-text .user-block {
  transition: padding 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

/* ========================================================================
     Component: top-navbar
 ========================================================================== */
.topnavbar {
  -webkit-backface-visibility: hidden;
  /* fixes chrome jump */
  margin-bottom: 0;
  border-radius: 0;
  z-index: 1050;
  border: 0;
  padding: 0;
  min-height: 55px;
  background-color: #23b7e5;
  background-image: linear-gradient(to right, #23b7e5 0%, #51c6ea 100%);
  background-repeat: repeat-x; }
  @media (min-width: 768px) {
    .topnavbar .navbar-header {
      width: 220px;
      text-align: center; }
      .topnavbar .navbar-header .navbar-brand {
        width: 100%; } }

.topnavbar {
  position: relative; }
  .topnavbar .navbar-header {
    background-color: transparent; }
  .topnavbar .navbar-header {
    position: relative;
    z-index: 11;
    padding-left: 2rem; }
    @media (min-width: 768px) {
      .topnavbar .navbar-header {
        padding-left: 0; } }
    .topnavbar .navbar-header .navbar-brand {
      padding: 0; }
    .topnavbar .navbar-header .brand-logo > img,
    .topnavbar .navbar-header .brand-logo-collapsed > img {
      margin: 0 auto; }
    .topnavbar .navbar-header .brand-logo {
      display: block;
      padding: 10px 15px; }
    .topnavbar .navbar-header .brand-logo-collapsed {
      display: none;
      padding: 6px 15px; }
  .topnavbar .dropdown {
    position: static; }
    .topnavbar .dropdown .dropdown-menu {
      position: absolute;
      margin-top: 0;
      top: auto;
      left: 0;
      right: 0; }
  @media (min-width: 576px) {
    .topnavbar .dropdown {
      position: relative; }
      .topnavbar .dropdown .dropdown-menu {
        top: 54px;
        left: 0;
        right: auto; }
      .topnavbar .dropdown .dropdown-menu-right {
        right: 0;
        left: auto; } }
  .topnavbar .navbar-nav > .nav-item > .navbar-text {
    color: #fff; }
  .topnavbar .navbar-nav > .nav-item > .nav-link {
    padding: 1.1rem .95rem;
    font-size: .85rem; }
  .topnavbar .navbar-nav > .nav-item > .nav-link,
  .topnavbar .navbar-nav > .nav-item.show > .nav-link {
    color: #fff; }
    .topnavbar .navbar-nav > .nav-item > .nav-link:hover, .topnavbar .navbar-nav > .nav-item > .nav-link:focus,
    .topnavbar .navbar-nav > .nav-item.show > .nav-link:hover,
    .topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
      color: #117391; }
  .topnavbar .navbar-nav > .nav-item.active > .nav-link, .topnavbar .navbar-nav > .nav-item.active > .nav-link:hover, .topnavbar .navbar-nav > .nav-item.active > .nav-link:focus,
  .topnavbar .navbar-nav > .nav-item.show > .nav-link,
  .topnavbar .navbar-nav > .nav-item.show > .nav-link:hover,
  .topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
    display: inline-block;
    box-shadow: 0 -3px 0 rgba(0, 0, 0, 0.125) inset;
    background-color: transparent;
    transition: all 0.2s; }
    @media (prefers-reduced-motion: reduce) {
      .topnavbar .navbar-nav > .nav-item.active > .nav-link, .topnavbar .navbar-nav > .nav-item.active > .nav-link:hover, .topnavbar .navbar-nav > .nav-item.active > .nav-link:focus,
      .topnavbar .navbar-nav > .nav-item.show > .nav-link,
      .topnavbar .navbar-nav > .nav-item.show > .nav-link:hover,
      .topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
        transition: none; } }
  .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    font-size: 16px;
    line-height: 55px;
    color: #fff;
    padding-top: 0;
    padding-bottom: 0;
    transition: color 0.3s ease; }
    @media (prefers-reduced-motion: reduce) {
      .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
        transition: none; } }
    @media (min-width: 768px) {
      .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
        color: #fff; } }
  @media (max-width: 767.98px) {
    .topnavbar .navbar-text {
      margin: 10px; } }

.layout-h .topnavbar .navbar-header {
  display: flex;
  width: 100%;
  padding: 0;
  text-align: left; }
  @media (min-width: 992px) {
    .layout-h .topnavbar .navbar-header {
      width: auto; } }
  .layout-h .topnavbar .navbar-header .navbar-brand {
    min-height: 55px; }

.layout-h .topnavbar .navbar-toggler {
  border: 0;
  margin-left: auto; }
  .layout-h .topnavbar .navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255, 255, 255, 0.75)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>"); }

@media (max-width: 991.98px) {
  .layout-h .topnavbar .dropdown-menu {
    left: 0 !important;
    right: 0 !important; } }

.layout-h .topnavbar .navbar-form {
  left: 0; }

@media (max-width: 767.98px) {
  .sidebar-toggle {
    position: absolute !important;
    top: 2px;
    left: 0;
    z-index: 3001; }
    .sidebar-toggle > em {
      color: white; } }

.topnavbar .navbar-form {
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  height: 55px;
  z-index: 9001;
  transition: all .3s;
  border: 0;
  border-bottom: 1px solid #e1e2e3; }
  .topnavbar .navbar-form .form-group {
    height: 100%;
    width: 100%; }
  .topnavbar .navbar-form .form-control {
    height: 100%;
    border: 0;
    border-radius: 0;
    width: 100%; }
  .topnavbar .navbar-form.open {
    top: 0; }
  .topnavbar .navbar-form .navbar-form-close {
    position: absolute;
    height: 30px;
    cursor: pointer;
    top: 50%;
    right: 0;
    margin-top: -15px;
    line-height: 30px;
    margin-right: 10px;
    color: #c1c2c3;
    font-size: 1.5em;
    pointer-events: auto; }

@media (min-width: 576px) {
  .topnavbar .navbar-form {
    left: 220px; } }

@media (min-width: 992px) {
  .topnavbar .navbar-nav > .nav-item.show > .nav-link, .topnavbar .navbar-nav > .nav-item.show > .nav-link:hover, .topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
    box-shadow: 0 -3px 0 #19a5d1 inset;
    transition: all 0.2s; } }
  @media (min-width: 992px) and (prefers-reduced-motion: reduce) {
    .topnavbar .navbar-nav > .nav-item.show > .nav-link, .topnavbar .navbar-nav > .nav-item.show > .nav-link:hover, .topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
      transition: none; } }

@media (min-width: 768px) {
  .aside-collapsed .topnavbar .navbar-header .brand-logo {
    display: none; }
  .aside-collapsed .topnavbar .navbar-header .brand-logo-collapsed {
    display: block; }
  .aside-collapsed .topnavbar .navbar-header {
    width: 70px; }
  .aside-collapsed .topnavbar .navbar-form {
    left: 70px; } }

@media (min-width: 768px) {
  .aside-collapsed-text .topnavbar .navbar-header .brand-logo {
    display: none; }
  .aside-collapsed-text .topnavbar .navbar-header .brand-logo-collapsed {
    display: block; }
  .aside-collapsed-text .topnavbar .navbar-header {
    width: 90px; }
  .aside-collapsed-text .topnavbar .navbar-form {
    left: 90px; } }

/* ========================================================================
     Component: sidebar
 ========================================================================== */
.sidebar {
  height: 100%;
  padding-bottom: 20px;
  background-color: #fff;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; }
  .sidebar:after {
    content: "";
    background: rgba(0, 0, 0, 0.15);
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    -webkit-transform: translateZ(0px); }
  .sidebar .nav-heading {
    padding: 12px 15px;
    color: #919DA8;
    font-size: 13px;
    letter-spacing: .035em;
    pointer-events: none;
    cursor: default; }

.sidebar-nav {
  position: relative;
  font-size: 14px;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none; }
  .sidebar-nav > .nav-heading:first-child {
    padding-top: 20px; }
  .sidebar-nav > li {
    display: block;
    border-left: 3px solid transparent;
    transition: border-left-color 0.4s ease; }
    @media (prefers-reduced-motion: reduce) {
      .sidebar-nav > li {
        transition: none; } }
    .sidebar-nav > li > a,
    .sidebar-nav > li > .nav-item {
      position: relative;
      display: block;
      padding: 12px 24px;
      color: #515253;
      letter-spacing: .025em;
      font-weight: normal;
      cursor: pointer; }
      .sidebar-nav > li > a:focus, .sidebar-nav > li > a:hover,
      .sidebar-nav > li > .nav-item:focus,
      .sidebar-nav > li > .nav-item:hover {
        text-decoration: none;
        outline: none;
        color: #23b7e5; }
      .sidebar-nav > li > a > em,
      .sidebar-nav > li > .nav-item > em {
        width: 1.8em;
        display: inline-block;
        font-style: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        color: inherits; }
    .sidebar-nav > li.active,
    .sidebar-nav > li.active > a,
    .sidebar-nav > li.active > .nav-item,
    .sidebar-nav > li.active .sidebar-nav, .sidebar-nav > li.open,
    .sidebar-nav > li.open > a,
    .sidebar-nav > li.open > .nav-item,
    .sidebar-nav > li.open .sidebar-nav {
      background-color: #fcfcfc;
      color: #23b7e5; }
    .sidebar-nav > li.active > .nav-item > em,
    .sidebar-nav > li.active > a > em, .sidebar-nav > li.open > .nav-item > em,
    .sidebar-nav > li.open > a > em {
      color: #23b7e5; }
    .sidebar-nav > li.active {
      border-left-color: #23b7e5; }
  .sidebar-nav .badge {
    display: block; }

@media only screen and (min-width: 1025px) {
  .sidebar:not(.show-scrollbar) {
    margin-right: -17px;
    overflow-y: scroll; } }

.sidebar-subnav {
  background-color: #fff; }
  .sidebar-subnav > .sidebar-subnav-header {
    color: #515253;
    display: none;
    padding: 10px 20px;
    font-weight: bold; }
  .sidebar-subnav > li {
    border-left: 0 !important; }
    .sidebar-subnav > li > a,
    .sidebar-subnav > li > .nav-item {
      display: block;
      position: relative;
      padding: 10px 20px;
      padding-left: 53px;
      font-weight: normal;
      background-color: transparent !important;
      color: #515253; }
      .sidebar-subnav > li > a:focus, .sidebar-subnav > li > a:hover,
      .sidebar-subnav > li > .nav-item:focus,
      .sidebar-subnav > li > .nav-item:hover {
        color: #23b7e5; }
      .sidebar-subnav > li > a > em,
      .sidebar-subnav > li > .nav-item > em {
        display: inline-block;
        width: 1.8em;
        margin: 0 0 0 -2em; }
    .sidebar-subnav > li.active > a,
    .sidebar-subnav > li.active > .nav-item {
      color: #23b7e5; }
      .sidebar-subnav > li.active > a:after,
      .sidebar-subnav > li.active > .nav-item:after {
        border-color: #23b7e5;
        background-color: #23b7e5; }
    .sidebar-subnav > li .sidebar-nav > li {
      padding-left: 5px; }
  .sidebar-subnav.nav-floating {
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-left: -1px; }
    .sidebar-subnav.nav-floating,
    .sidebar-subnav.nav-floating .collapse, .sidebar-subnav.nav-floating .sidebar-subnav {
      height: auto !important;
      display: block !important;
      visibility: visible !important; }
      .sidebar-subnav.nav-floating.opening,
      .sidebar-subnav.nav-floating .collapse.opening, .sidebar-subnav.nav-floating .sidebar-subnav.opening {
        -webkit-animation: none !important;
                animation: none !important; }
    .sidebar-subnav.nav-floating > .sidebar-subnav-header {
      display: block; }
    .sidebar-subnav.nav-floating li > a,
    .sidebar-subnav.nav-floating li > .nav-item {
      padding-left: 20px; }
      .sidebar-subnav.nav-floating li > a em,
      .sidebar-subnav.nav-floating li > .nav-item em {
        margin-left: 0; }

@media (min-width: 768px) {
  .sidebar > .sidebar-nav .badge {
    margin: 2px 0 0 0; } }

.aside-collapsed .sidebar,
.aside-collapsed-text .sidebar {
  overflow-x: hidden; }
  .aside-collapsed .sidebar > .sidebar-nav .sidebar-nav,
  .aside-collapsed .sidebar > .sidebar-nav > .nav-heading,
  .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item > span,
  .aside-collapsed .sidebar > .sidebar-nav > li > a > span,
  .aside-collapsed-text .sidebar > .sidebar-nav .sidebar-nav,
  .aside-collapsed-text .sidebar > .sidebar-nav > .nav-heading,
  .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item > span,
  .aside-collapsed-text .sidebar > .sidebar-nav > li > a > span {
    display: none !important; }
  .aside-collapsed .sidebar > .sidebar-nav > li,
  .aside-collapsed-text .sidebar > .sidebar-nav > li {
    width: 69px; }
    .aside-collapsed .sidebar > .sidebar-nav > li > a,
    .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item,
    .aside-collapsed-text .sidebar > .sidebar-nav > li > a,
    .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item {
      text-indent: -3px;
      padding: 20px 0;
      text-align: center; }
      .aside-collapsed .sidebar > .sidebar-nav > li > a > em,
      .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item > em,
      .aside-collapsed-text .sidebar > .sidebar-nav > li > a > em,
      .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item > em {
        font-size: 1.6em;
        width: auto; }
      .aside-collapsed .sidebar > .sidebar-nav > li > a:focus,
      .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item:focus,
      .aside-collapsed-text .sidebar > .sidebar-nav > li > a:focus,
      .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item:focus {
        background-color: transparent; }
        .aside-collapsed .sidebar > .sidebar-nav > li > a:focus > em,
        .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item:focus > em,
        .aside-collapsed-text .sidebar > .sidebar-nav > li > a:focus > em,
        .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item:focus > em {
          color: inherit; }
  .aside-collapsed .sidebar .sidebar-nav .badge,
  .aside-collapsed-text .sidebar .sidebar-nav .badge {
    position: absolute;
    top: 10px;
    right: 5px;
    text-indent: 0; }

.aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item > span,
.aside-collapsed-text .sidebar > .sidebar-nav > li > a > span {
  display: block !important;
  font-size: 12px; }

.aside-collapsed-text .sidebar > .sidebar-nav > li {
  width: 88px; }
  .aside-collapsed-text .sidebar > .sidebar-nav > li > a {
    padding: 14px 0; }
    .aside-collapsed-text .sidebar > .sidebar-nav > li > a > em {
      font-size: 1.4em; }

/* ========================================================================
     Component: offsidebar
 ========================================================================== */
.offsidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 235px;
  margin-top: 55px;
  border-left: 1px solid #cccccc;
  background-color: #fff;
  color: #656565;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: -1; }
  .offsidebar > .list-group {
    min-height: 100%;
    overflow: hidden;
    -webkit-transform: translateZ(0px); }
  .offsidebar .progress {
    border: 0; }
  .offsidebar .tab-content {
    padding: 0;
    border: 0; }
  .offsidebar .nav-tabs {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
    .offsidebar .nav-tabs > .nav-item > .nav-link {
      background-color: transparent;
      border: 0;
      border-right: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 0;
      color: #909FA7; }
      .offsidebar .nav-tabs > .nav-item > .nav-link.active {
        color: #5d9cec; }
  @media (min-width: 768px) {
    .offsidebar {
      margin-top: 55px; } }

.offsidebar-open .offsidebar {
  overflow-y: auto;
  z-index: 116; }

@media (min-width: 768px) {
  .offsidebar-open {
    overflow-y: auto; } }

.offsidebar {
  right: -235px; }

.no-csstransforms3d .offsidebar-open .offsidebar {
  right: 0; }

/* Transformation ready devices*/
.csstransforms3d .offsidebar {
  right: 0;
  -webkit-transform: translate3d(235px, 0, 0);
          transform: translate3d(235px, 0, 0);
  transition: z-index 0s linear .3s, -webkit-transform .3s ease;
  transition: transform .3s ease, z-index 0s linear .3s;
  transition: transform .3s ease, z-index 0s linear .3s, -webkit-transform .3s ease; }

.csstransforms3d .offsidebar-open .offsidebar {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  transition: -webkit-transform .3s ease;
  transition: transform .3s ease;
  transition: transform .3s ease, -webkit-transform .3s ease; }

/* ========================================================================
     Component: user-block
 ========================================================================== */
.has-user-block {
  display: block;
  overflow: hidden;
  border: 0 !important; }

.user-block {
  position: relative;
  padding: 25px 0 10px;
  cursor: pointer; }
  .user-block::after {
    display: block;
    clear: both;
    content: ""; }
  .user-block > .user-block-picture {
    position: relative;
    width: 60px;
    margin: 0 auto; }
    .user-block > .user-block-picture > img {
      max-width: 100%;
      height: auto; }
  .user-block .user-block-info {
    padding-top: 15px;
    text-align: center;
    white-space: nowrap; }
    .user-block .user-block-info .user-block-name, .user-block .user-block-info .user-block-role {
      display: block; }
    .user-block .user-block-info .user-block-name {
      color: #7D848F; }
    .user-block .user-block-info .user-block-role {
      font-size: 12px;
      color: #aaa; }

.user-block-status {
  position: relative; }
  .user-block-status > .circle {
    position: absolute;
    bottom: 0;
    right: 0;
    border: 2px solid #fff; }

.aside-collapsed .user-block,
.aside-collapsed-text .user-block {
  padding: 15px 0 14px;
  margin: 0;
  text-align: center; }
  .aside-collapsed .user-block > .user-block-picture, .aside-collapsed-text .user-block > .user-block-picture {
    float: none;
    margin: 0  auto;
    width: 50px; }
    .aside-collapsed .user-block > .user-block-picture > .user-block-status, .aside-collapsed-text .user-block > .user-block-picture > .user-block-status {
      display: block; }
  .aside-collapsed .user-block .user-block-info, .aside-collapsed-text .user-block .user-block-info {
    display: none; }

.setting-color {
  padding: 0 5px; }
  .setting-color > label {
    display: block;
    position: relative;
    margin: 0 10px;
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer; }
    .setting-color > label:first-child {
      margin-left: 0; }
    .setting-color > label:last-child {
      margin-right: 0; }
    .setting-color > label > .color {
      display: block;
      height: 18px; }
    .setting-color > label > .split {
      display: block; }
      .setting-color > label > .split::after {
        display: block;
        clear: both;
        content: ""; }
      .setting-color > label > .split > .color {
        display: block;
        height: 37.5px; }
        .setting-color > label > .split > .color:first-child {
          float: left;
          width: 70%; }
        .setting-color > label > .split > .color:last-child {
          float: right;
          width: 30%; }
    .setting-color > label > .icon-check {
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      width: 20px;
      height: 20px;
      margin-top: -20px;
      margin-left: -10px;
      text-align: center;
      font-size: 1.33333333em;
      vertical-align: -15%;
      color: #fff;
      opacity: 0; }
    .setting-color > label > input[type="radio"] {
      position: absolute;
      opacity: 0;
      visibility: hidden; }
      .setting-color > label > input[type="radio"]:checked + .icon-check {
        opacity: 1 !important; }

/* ========================================================================
     Component: Page loader
 ========================================================================== */
.page-loader {
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  padding-top: 30px;
  text-align: center; }

@media (min-width: 768px) {
  .section-container .page-loader {
    top: 55px;
    left: 220px; }
  .aside-collapsed .section-container .page-loader {
    left: 70px; }
  .aside-collapsed-text .section-container .page-loader {
    left: 90px; } }

/* ========================================================================
     Component: typo
 ========================================================================== */
body {
  font-family: "Source Sans Pro", sans-serif;
  color: #656565;
  font-size: 0.875rem; }

h1, h2, h3, h4 {
  font-weight: bold; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.65625rem; }

h1, .h1 {
  font-size: 2.25rem; }

h2, .h2 {
  font-size: 1.875rem; }

h3, .h3 {
  font-size: 1.5rem; }

h4, .h4 {
  font-size: 1.125rem; }

h5, .h5 {
  font-size: .875rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-bottom: .65rem; }

/* ========================================================================
     Component: bootstrap-reset
 ========================================================================== */
*:not(.react-grid-Cell):focus {
  outline: 0 !important; }

a {
  outline: none !important; }
  a.text-muted:hover, a.text-muted:focus {
    color: #748690; }

hr {
  border-top: 1px solid #e4eaec; }

[data-now] {
  display: inline-block; }

.badge {
  padding: 0.1875rem 0.4375rem;
  font-size: 0.75rem;
  border-radius: 0.625rem; }
  .badge.badge-warning {
    color: #fff !important; }
  .badge.badge-secondary {
    background-color: #3a3f51;
    color: #fff; }

.list-group {
  line-height: 1.3; }
  .list-group .list-group-item {
    padding: 10px;
    color: #555; }
    .list-group .list-group-item.active {
      color: #fff; }
      .list-group .list-group-item.active .badge {
        background-color: #fff;
        color: #5d9cec; }
    .card > .list-group .list-group-item {
      border: 0; }

.list-group + .card-footer {
  border-top: 0; }

.page-header {
  padding-bottom: 0.59375rem;
  margin: 2.625rem 0 1.3125rem;
  border-bottom: 1px solid #e4eaec; }

.card {
  border-color: transparent;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05); }
  .card .card-header {
    border-bottom: 0;
    padding: 0.625rem 0.9375rem;
    background-color: transparent; }
    .card .card-header .card-title {
      margin-bottom: 0;
      font-size: 1rem; }
    .card .card-header a {
      text-decoration: none !important; }

.card-body {
  padding: 0.9375rem; }

.card-footer {
  padding: 0.625rem 0.9375rem; }

.card-default {
  border-top-width: 3px;
  border-color: #cfdbe2; }
  .card-default .card-header {
    background-color: #fff; }

.well {
  border: 1px solid #dde6e9; }

.jumbotron {
  border: 1px solid #dde6e9;
  background-color: #fff; }
  @media (min-width: 768px) {
    .jumbotron {
      padding: 2rem 1rem; } }

.nav-tabs > .nav-item > .nav-link {
  font-weight: bold;
  color: #656565;
  background-color: #edf1f2;
  margin: 0;
  border: 1px solid #dde6e9;
  border-radius: 0;
  padding: 10px 20px; }
  .nav-tabs > .nav-item > .nav-link.active {
    background-color: #fff; }
    .nav-tabs > .nav-item > .nav-link.active, .nav-tabs > .nav-item > .nav-link.active:hover, .nav-tabs > .nav-item > .nav-link.active:focus {
      border-bottom-color: #fff; }

.tab-content {
  padding: 10px 20px;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-color: #dde6e9; }
  .nav-pills + .tab-content {
    border: 0;
    padding: 0; }
  .p-0 .tab-content {
    padding: 0 !important; }

.btn {
  border-radius: 3px;
  font-size: 13px;
  -webkit-appearance: none;
  outline: none !important;
  transition: all 0.1s; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn.btn-link {
    box-shadow: none;
    border: 0; }
  .input-group .btn {
    font-size: 14px;
    border-color: #dde6e9; }
  .input-group .form-control-sm + .input-group-btn .btn {
    font-size: 0.8125rem;
    padding: 0.3125rem 0.625rem;
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.btn.btn-warning {
  color: #fff; }

.btn.btn-outline-warning:not(:disabled):hover, .btn.btn-outline-warning:not(:disabled):active {
  color: #fff; }

.btn-secondary {
  background-color: #fff;
  border-color: #eaeaea;
  color: #333; }
  .btn-secondary:hover {
    background-color: #f5f5f5; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    background-color: #f5f5f5;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.btn-outline-secondary {
  border-color: #eaeaea !important;
  color: #333; }
  .btn-outline-secondary:hover {
    color: #333;
    background-color: #f5f5f5 !important; }
  .btn-outline-secondary:focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 234, 234, 0.5); }

span.btn {
  -webkit-appearance: none !important; }

.form-control {
  box-shadow: 0 0 0 #000 !important;
  font-size: 0.875rem; }
  .form-control:focus {
    border-color: #66afe9; }

.form-control-sm,
select.form-control-sm {
  font-size: .75rem;
  height: 31px; }

.custom-select {
  -webkit-appearance: none; }

.custom-control-label::before,
.custom-control-label::after {
  top: 0.19rem; }

.custom-control-label::before {
  border: 1px solid #dde6e9; }

fieldset {
  padding-bottom: 20px;
  border-bottom: 1px dashed #eee;
  margin-bottom: 20px; }
  fieldset.last-child, fieldset:last-child {
    border-bottom: 0; }
  fieldset .form-group {
    margin-bottom: 0; }

.input-group-text {
  font-size: .875rem; }

@media (max-width: 767.98px) {
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="password"] {
    -webkit-appearance: none; } }

.table-responsive {
  overflow-y: hidden; }

.table > thead > tr > th {
  border-bottom-width: 0; }

.table > tbody + tbody {
  border-bottom-width: 1px; }

.table .form-control {
  max-width: 100%; }

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
  border-bottom-width: 1px; }

.progress {
  border-radius: 0.2rem;
  border: 1px solid #e9ecef;
  background-color: #fff; }

.popover {
  box-shadow: 0 0 0 #000;
  border-color: #eee;
  border-bottom: 2px solid #e4eaec;
  border-radius: 0.2rem; }
  .popover .popover-title {
    border: 0; }

.nav.nav-pills .active > a {
  background-color: #5d9cec; }

.dropdown-menu {
  border-radius: 0.2rem;
  font-size: 14px;
  padding: 0.3125rem 0;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 6px 12px;
  border-color: #e1e1e1; }
  .dropdown-menu .dropdown-item {
    line-height: 1.52857143;
    padding: 0.1875rem 1.25rem; }

.dropdown-header {
  color: #a1a2a3; }

.navbar-top .navbar-nav > .active > a {
  color: #999; }
  .navbar-top .navbar-nav > .active > a:hover, .navbar-top .navbar-nav > .active > a:focus {
    color: #d1d2d3; }

.navbar-default .navbar-nav .open .dropdown-menu > li > a {
  color: #666; }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #222; }

.carousel .carousel-indicators {
  bottom: 0; }

.carousel .carousel-control.left, .carousel .carousel-control.right {
  background-image: none; }

.carousel .carousel-control em {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px; }

.alert-primary {
  color: #fff;
  background-color: #5d9cec;
  border-color: #5d9cec; }
  .alert-primary hr {
    border-top-color: #468ee9; }
  .alert-primary .alert-link {
    color: #e6e6e6; }

.alert-secondary {
  color: #fff;
  background-color: #fff;
  border-color: #fff; }
  .alert-secondary hr {
    border-top-color: #f2f2f2; }
  .alert-secondary .alert-link {
    color: #e6e6e6; }

.alert-success {
  color: #fff;
  background-color: #27c24c;
  border-color: #27c24c; }
  .alert-success hr {
    border-top-color: #23ad44; }
  .alert-success .alert-link {
    color: #e6e6e6; }

.alert-info {
  color: #fff;
  background-color: #23b7e5;
  border-color: #23b7e5; }
  .alert-info hr {
    border-top-color: #19a9d5; }
  .alert-info .alert-link {
    color: #e6e6e6; }

.alert-warning {
  color: #fff;
  background-color: #ff902b;
  border-color: #ff902b; }
  .alert-warning hr {
    border-top-color: #ff8312; }
  .alert-warning .alert-link {
    color: #e6e6e6; }

.alert-danger {
  color: #fff;
  background-color: #f05050;
  border-color: #f05050; }
  .alert-danger hr {
    border-top-color: #ee3939; }
  .alert-danger .alert-link {
    color: #e6e6e6; }

.alert-secondary {
  color: #656565;
  border-color: #e4eaec; }

.topnavbar,
.navbar,
.navbar .dropdown-menu {
  -webkit-filter: none !important;
          filter: none !important; }

/* ========================================================================
     Component: bootstrap-custom
 ========================================================================== */
.container-sm {
  max-width: 540px;
  width: auto; }

.container-md {
  max-width: 720px;
  width: auto; }

.container-lg {
  max-width: 960px;
  width: auto; }

.row-flush {
  margin: 0; }
  .row-flush > .col,
  .row-flush > [class*="col-"] {
    padding-left: 0;
    padding-right: 0; }

.breadcrumb {
  font-weight: normal;
  border-radius: 0;
  color: #909FA7;
  padding: 10px 20px; }

.content-heading .breadcrumb {
  font-size: 0.8125rem;
  margin-bottom: 0; }

.content-heading + .breadcrumb {
  margin: -25px -25px 20px -20px;
  background-color: #fafbfc;
  border-top: 1px solid #cfdbe2;
  border-bottom: 1px solid #cfdbe2; }

.progress-sm {
  height: 15px; }

.progress-xs {
  height: 8px; }

.badge-inverse {
  color: #fff;
  background-color: #131e26; }
  a.badge-inverse:hover, a.badge-inverse:focus {
    color: #fff;
    background-color: #020304; }
  a.badge-inverse:focus, a.badge-inverse.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(19, 30, 38, 0.5); }

.badge-green {
  color: #fff;
  background-color: #37bc9b; }
  a.badge-green:hover, a.badge-green:focus {
    color: #fff;
    background-color: #2b957a; }
  a.badge-green:focus, a.badge-green.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(55, 188, 155, 0.5); }

.badge-pink {
  color: #fff;
  background-color: #f532e5; }
  a.badge-pink:hover, a.badge-pink:focus {
    color: #fff;
    background-color: #e90bd6; }
  a.badge-pink:focus, a.badge-pink.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(245, 50, 229, 0.5); }

.badge-purple {
  color: #fff;
  background-color: #7266ba; }
  a.badge-purple:hover, a.badge-purple:focus {
    color: #fff;
    background-color: #564aa3; }
  a.badge-purple:focus, a.badge-purple.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(114, 102, 186, 0.5); }

.alert-purple {
  color: #fff;
  background-color: #7266ba;
  border-color: #7266ba; }
  .alert-purple hr {
    border-top-color: #6254b2; }
  .alert-purple .alert-link {
    color: #e6e6e6; }

.alert-green {
  color: #fff;
  background-color: #37bc9b;
  border-color: #37bc9b; }
  .alert-green hr {
    border-top-color: #31a88b; }
  .alert-green .alert-link {
    color: #e6e6e6; }

.alert-pink {
  color: #fff;
  background-color: #f532e5;
  border-color: #f532e5; }
  .alert-pink hr {
    border-top-color: #f41ae2; }
  .alert-pink .alert-link {
    color: #e6e6e6; }

.alert-inverse {
  color: #fff;
  background-color: #131e26;
  border-color: #131e26; }
  .alert-inverse hr {
    border-top-color: #0b1115; }
  .alert-inverse .alert-link {
    color: #e6e6e6; }

.form-control-rounded {
  border-radius: 100px; }

/* ========================================================================
     Component: button-extra
 ========================================================================== */
.btn-inverse {
  color: #fff;
  background-color: #131e26;
  border-color: #131e26; }
  .btn-inverse:hover {
    color: #fff;
    background-color: #060a0d;
    border-color: #020304; }
  .btn-inverse:focus, .btn-inverse.focus {
    color: #fff;
    background-color: #060a0d;
    border-color: #020304;
    box-shadow: 0 0 0 0.2rem rgba(54, 64, 71, 0.5); }
  .btn-inverse.disabled, .btn-inverse:disabled {
    color: #fff;
    background-color: #131e26;
    border-color: #131e26; }
  .btn-inverse:not(:disabled):not(.disabled):active, .btn-inverse:not(:disabled):not(.disabled).active,
  .show > .btn-inverse.dropdown-toggle {
    color: #fff;
    background-color: #020304;
    border-color: black; }
    .btn-inverse:not(:disabled):not(.disabled):active:focus, .btn-inverse:not(:disabled):not(.disabled).active:focus,
    .show > .btn-inverse.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(54, 64, 71, 0.5); }

.btn-green {
  color: #fff;
  background-color: #37bc9b;
  border-color: #37bc9b; }
  .btn-green:hover {
    color: #fff;
    background-color: #2e9e83;
    border-color: #2b957a; }
  .btn-green:focus, .btn-green.focus {
    color: #fff;
    background-color: #2e9e83;
    border-color: #2b957a;
    box-shadow: 0 0 0 0.2rem rgba(85, 198, 170, 0.5); }
  .btn-green.disabled, .btn-green:disabled {
    color: #fff;
    background-color: #37bc9b;
    border-color: #37bc9b; }
  .btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active,
  .show > .btn-green.dropdown-toggle {
    color: #fff;
    background-color: #2b957a;
    border-color: #298b72; }
    .btn-green:not(:disabled):not(.disabled):active:focus, .btn-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(85, 198, 170, 0.5); }

.btn-purple {
  color: #fff;
  background-color: #7266ba;
  border-color: #7266ba; }
  .btn-purple:hover {
    color: #fff;
    background-color: #5b4eac;
    border-color: #564aa3; }
  .btn-purple:focus, .btn-purple.focus {
    color: #fff;
    background-color: #5b4eac;
    border-color: #564aa3;
    box-shadow: 0 0 0 0.2rem rgba(135, 125, 196, 0.5); }
  .btn-purple.disabled, .btn-purple:disabled {
    color: #fff;
    background-color: #7266ba;
    border-color: #7266ba; }
  .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
  .show > .btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #564aa3;
    border-color: #52469b; }
    .btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(135, 125, 196, 0.5); }

.btn-pink {
  color: #fff;
  background-color: #f532e5;
  border-color: #f532e5; }
  .btn-pink:hover {
    color: #fff;
    background-color: #f30ee0;
    border-color: #e90bd6; }
  .btn-pink:focus, .btn-pink.focus {
    color: #fff;
    background-color: #f30ee0;
    border-color: #e90bd6;
    box-shadow: 0 0 0 0.2rem rgba(247, 81, 233, 0.5); }
  .btn-pink.disabled, .btn-pink:disabled {
    color: #fff;
    background-color: #f532e5;
    border-color: #f532e5; }
  .btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active,
  .show > .btn-pink.dropdown-toggle {
    color: #fff;
    background-color: #e90bd6;
    border-color: #dc0bcb; }
    .btn-pink:not(:disabled):not(.disabled):active:focus, .btn-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(247, 81, 233, 0.5); }

.btn-flat {
  border-bottom-width: 1px;
  border-radius: 0;
  box-shadow: 0 0 0 #000; }

.btn-xs {
  padding: 0.0625rem 0.3125rem;
  font-size: 0.8125rem;
  line-height: 1.5; }

.btn-xl {
  padding: 20px 16px;
  font-size: 18px; }

.btn-square {
  border-radius: 0; }

.btn-pill-left, .btn-oval {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding-left: 18px; }

.btn-pill-right, .btn-oval {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding-right: 18px; }

.btn-labeled {
  padding-top: 0;
  padding-bottom: 0; }

.btn-label {
  position: relative;
  background: transparent;
  background: rgba(0, 0, 0, 0.05);
  display: inline-block;
  padding: 0.375rem 1rem;
  left: -1rem;
  border-radius: 0.25rem 0 0 0.25rem; }
  .btn-label.btn-label-right {
    left: auto;
    right: -1rem;
    border-radius: 0 0.25rem 0.25rem 0; }

.btn-lg .btn-label {
  padding: 0.625rem 1.25rem;
  left: -1.25rem;
  border-radius: 0.375rem 0 0 0.375rem; }
  .btn-lg .btn-label.btn-label-right {
    left: auto;
    right: -1.25rem;
    border-radius: 0 0.375rem 0.375rem 0; }

.btn-sm .btn-label {
  padding: 0.3125rem 0.625rem;
  left: -0.625rem;
  border-radius: 0.2rem 0 0 0.2rem; }
  .btn-sm .btn-label.btn-label-right {
    left: auto;
    right: -0.625rem;
    border-radius: 0 0.2rem 0.2rem 0; }

.btn-xs .btn-label {
  padding: 1px 5px;
  left: -5px;
  border-radius: 0.2rem 0 0 0.2rem; }
  .btn-xs .btn-label.btn-label-right {
    left: auto;
    right: -5px;
    border-radius: 0 0.2rem 0.2rem 0; }

.btn-fw {
  min-width: 80px; }
  .btn-fw.btn-sm {
    min-width: 40px; }
  .btn-fw.btn-md {
    min-width: 60px; }
  .btn-fw.btn-lg {
    min-width: 140px; }

.btn-circle {
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 12px;
  line-height: 35px;
  border-radius: 500px;
  padding: 0;
  border: 0; }
  .btn-circle > * {
    line-height: inherit !important; }
  .btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    font-size: 18px;
    line-height: 50px; }

.btn-outline-inverse {
  color: #131e26;
  border-color: #131e26; }
  .btn-outline-inverse:hover {
    color: #fff;
    background-color: #131e26;
    border-color: #131e26; }
  .btn-outline-inverse:focus, .btn-outline-inverse.focus {
    box-shadow: 0 0 0 0.2rem rgba(19, 30, 38, 0.5); }
  .btn-outline-inverse.disabled, .btn-outline-inverse:disabled {
    color: #131e26;
    background-color: transparent; }
  .btn-outline-inverse:not(:disabled):not(.disabled):active, .btn-outline-inverse:not(:disabled):not(.disabled).active,
  .show > .btn-outline-inverse.dropdown-toggle {
    color: #fff;
    background-color: #131e26;
    border-color: #131e26; }
    .btn-outline-inverse:not(:disabled):not(.disabled):active:focus, .btn-outline-inverse:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-inverse.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(19, 30, 38, 0.5); }

.btn-outline-green {
  color: #37bc9b;
  border-color: #37bc9b; }
  .btn-outline-green:hover {
    color: #fff;
    background-color: #37bc9b;
    border-color: #37bc9b; }
  .btn-outline-green:focus, .btn-outline-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(55, 188, 155, 0.5); }
  .btn-outline-green.disabled, .btn-outline-green:disabled {
    color: #37bc9b;
    background-color: transparent; }
  .btn-outline-green:not(:disabled):not(.disabled):active, .btn-outline-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-green.dropdown-toggle {
    color: #fff;
    background-color: #37bc9b;
    border-color: #37bc9b; }
    .btn-outline-green:not(:disabled):not(.disabled):active:focus, .btn-outline-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(55, 188, 155, 0.5); }

.btn-outline-purple {
  color: #7266ba;
  border-color: #7266ba; }
  .btn-outline-purple:hover {
    color: #fff;
    background-color: #7266ba;
    border-color: #7266ba; }
  .btn-outline-purple:focus, .btn-outline-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(114, 102, 186, 0.5); }
  .btn-outline-purple.disabled, .btn-outline-purple:disabled {
    color: #7266ba;
    background-color: transparent; }
  .btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
  .show > .btn-outline-purple.dropdown-toggle {
    color: #fff;
    background-color: #7266ba;
    border-color: #7266ba; }
    .btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(114, 102, 186, 0.5); }

.btn-outline-pink {
  color: #f532e5;
  border-color: #f532e5; }
  .btn-outline-pink:hover {
    color: #fff;
    background-color: #f532e5;
    border-color: #f532e5; }
  .btn-outline-pink:focus, .btn-outline-pink.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 50, 229, 0.5); }
  .btn-outline-pink.disabled, .btn-outline-pink:disabled {
    color: #f532e5;
    background-color: transparent; }
  .btn-outline-pink:not(:disabled):not(.disabled):active, .btn-outline-pink:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pink.dropdown-toggle {
    color: #fff;
    background-color: #f532e5;
    border-color: #f532e5; }
    .btn-outline-pink:not(:disabled):not(.disabled):active:focus, .btn-outline-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 50, 229, 0.5); }

/* ========================================================================
     Component: placeholder
 ========================================================================== */
.box-placeholder {
  margin-bottom: 15px;
  padding: 20px;
  border: 1px dashed #ddd;
  background: #fafafa;
  color: #444; }

.box-placeholder > :last-child {
  margin-bottom: 0; }

.box-placeholder-lg {
  padding-top: 80px;
  padding-bottom: 80px; }

/* ========================================================================
     Component: cards
 ========================================================================== */
.card {
  margin-bottom: 1.25rem; }

.card .table {
  margin-bottom: 0; }
  .card .table > thead > tr > th {
    border-top: 0; }

.card.card-transparent {
  border: 0;
  background-color: transparent;
  box-shadow: 0 0 0 #000; }
  .card.card-transparent .card-header,
  .card.card-transparent .card-body {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0; }

.card > .list-group {
  border-top: 0;
  border-bottom: 0; }

.card-flat {
  margin: 0 !important;
  border: 0; }

.card-columns-2 {
  -webkit-column-count: 1;
          column-count: 1; }
  @media (min-width: 768px) {
    .card-columns-2 {
      -webkit-column-count: 2;
              column-count: 2; } }

.card-tool {
  display: inline-block;
  color: #fff;
  text-align: center; }
  .card-tool:hover {
    cursor: pointer; }
  .card-tool > em + em {
    margin-left: 12px; }

.card-default .card-header > .card-tool {
  color: #c1c2c3; }

.card-header > .badge.float-right {
  margin-top: 3px; }
  .card-header > .badge.float-right + .badge.float-right {
    margin-right: 10px; }

.card-footer .pagination {
  margin: 0; }

.card-footer .radial-bar {
  margin-bottom: 0; }

.card-footer p {
  margin-bottom: 0; }

/* ========================================================================
     Component: circles
 ========================================================================== */
.circle {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 500px;
  margin: 0 .5em;
  background-color: #ddd;
  vertical-align: baseline;
  border: 2px solid transparent; }
  .circle.text-left {
    margin-left: 0; }
  .circle.text-right {
    margin-right: 0; }

.circle-sm {
  width: 5px;
  height: 5px; }

.circle-lg {
  width: 11px;
  height: 11px; }

.circle-xl {
  width: 18px;
  height: 18px; }

/* ========================================================================
     Component: dropdown-extras
 ========================================================================== */
.dropdown-list .dropdown-item {
  padding: 0; }

.dropdown-list > .dropdown-menu {
  padding: 0;
  min-width: 220px; }

.dropdown-list .list-group {
  margin: 0; }

.dropdown-list .list-group-item {
  border-radius: 0;
  border-left: 0;
  border-right: 0; }
  .dropdown-list .list-group-item:first-child {
    border-top: 0; }
  .dropdown-list .list-group-item:last-child {
    border-bottom: 0; }

.dropdown > a {
  position: relative; }
  .dropdown > a > .badge {
    position: absolute;
    top: 10px;
    right: 0;
    padding: 2px 5px; }

.dropdown-menu-right-forced {
  right: 0 !important;
  left: auto !important; }

.dropdown-toggle-nocaret:after {
  display: none; }

/* ========================================================================
     Component: half-float
 ========================================================================== */
.half-float {
  position: relative;
  margin-bottom: 69px; }
  .half-float .half-float-bottom,
  .half-float .half-float-top {
    position: absolute;
    left: 50%;
    bottom: -64px;
    width: 128px;
    height: 128px;
    margin-left: -64px;
    z-index: 2; }
  .half-float .half-float-top {
    bottom: auto;
    top: -64px; }

/* ========================================================================
     Component: animate
 ========================================================================== */
@-webkit-keyframes fadeOutUpShort {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); } }

@keyframes fadeOutUpShort {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); } }

.fadeOutUpShort {
  -webkit-animation-name: fadeOutUpShort;
  animation-name: fadeOutUpShort; }

@-webkit-keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInUpShort {
  -webkit-animation-name: fadeInUpShort;
  animation-name: fadeInUpShort; }

@-webkit-keyframes zoomInShort {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95); }
  50% {
    opacity: 1; } }

@keyframes zoomInShort {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95); }
  50% {
    opacity: 1; } }

.zoomInShort {
  -webkit-animation-name: zoomInShort;
  animation-name: zoomInShort; }

@-webkit-keyframes zoomBack {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5); }
  100% {
    opacity: 0; } }

@keyframes zoomBack {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5); }
  100% {
    opacity: 0; } }

.zoomBack {
  -webkit-animation-name: zoomBack;
  animation-name: zoomBack; }

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

.rag-fadeIn-enter {
  -webkit-animation: fadeIn 0.5s;
          animation: fadeIn 0.5s; }

.rag-fadeIn-exit {
  display: none; }

.rag-fadeInRight-enter {
  -webkit-animation: fadeInRight 0.5s;
          animation: fadeInRight 0.5s; }

.rag-fadeInRight-exit {
  display: none; }

.rag-fadeInLeft-enter {
  -webkit-animation: fadeInLeft 0.5s;
          animation: fadeInLeft 0.5s; }

.rag-fadeInLeft-exit {
  display: none; }

/* ========================================================================
     Component: slim-scroll
 ========================================================================== */
[data-scrollable] {
  display: block; }

.slimScrollBar {
  opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.35) !important;
  border: 0 !important;
  border-radius: 1px !important; }

.slimScrollRail {
  opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.15) !important;
  border: 0 !important;
  border-radius: 0 !important;
  bottom: 0; }

/* ========================================================================
     Component: inputs
 ========================================================================== */
/**
 * Custom form elements
 *    - Checkbox
 *    - Radios
 *    - Switch
 *    - Note editor
 */
.c-checkbox,
.c-radio {
  margin-right: 4px; }
  .c-checkbox *,
  .c-radio * {
    cursor: pointer; }
  .c-checkbox input,
  .c-radio input {
    opacity: 0;
    position: absolute;
    margin-left: 0 !important; }
  .c-checkbox span,
  .c-radio span {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 1px solid #ccc;
    margin-right: 5px;
    text-align: center; }
    .c-checkbox span:before,
    .c-radio span:before {
      margin-left: 1px; }
  .c-checkbox:hover span,
  .c-radio:hover span {
    border-color: #5d9cec; }
  .form-inline .c-checkbox span, .form-inline
  .c-radio span {
    margin-left: 0; }
  .c-checkbox.c-checkbox-rounded span, .c-checkbox.c-radio-rounded span,
  .c-radio.c-checkbox-rounded span,
  .c-radio.c-radio-rounded span {
    border-radius: 500px; }

/* override for radio */
.c-radio span {
  border-radius: 500px; }

/* the icon */
.c-checkbox span:before,
.c-radio span:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  text-align: center !important;
  font-size: 12px;
  line-height: 18px;
  vertical-align: middle; }

.c-checkbox,
.c-radio {
  /* Checked state */
  /* override for radio */
  /* Disable state */
  /* override for radio */
  /* Focus state */ }
  .c-checkbox input[type=checkbox]:checked + span:before,
  .c-checkbox input[type=radio]:checked + span:before,
  .c-radio input[type=checkbox]:checked + span:before,
  .c-radio input[type=radio]:checked + span:before {
    color: #fff;
    opacity: 1;
    transition: color .3s ease-out; }
  .c-checkbox input[type=checkbox]:checked + span,
  .c-checkbox input[type=radio]:checked + span,
  .c-radio input[type=checkbox]:checked + span,
  .c-radio input[type=radio]:checked + span {
    border-color: #5d9cec;
    background-color: #5d9cec; }
  .c-checkbox input[type=radio]:checked + span,
  .c-radio input[type=radio]:checked + span {
    background-color: #fff; }
    .c-checkbox input[type=radio]:checked + span:before,
    .c-radio input[type=radio]:checked + span:before {
      color: #5d9cec; }
  .c-checkbox input[type=checkbox]:disabled + span,
  .c-checkbox input[type=radio]:disabled + span,
  .c-radio input[type=checkbox]:disabled + span,
  .c-radio input[type=radio]:disabled + span {
    border-color: #ddd !important;
    background-color: #ddd !important; }
  .c-checkbox input[type=radio]:disabled + span,
  .c-radio input[type=radio]:disabled + span {
    background-color: #fff !important; }
    .c-checkbox input[type=radio]:disabled + span:before,
    .c-radio input[type=radio]:disabled + span:before {
      color: #ddd; }
  .c-checkbox input[type=checkbox]:focus + span,
  .c-checkbox input[type=radio]:focus + span,
  .c-radio input[type=checkbox]:focus + span,
  .c-radio input[type=radio]:focus + span {
    box-shadow: 0 0 0 0.2rem rgba(93, 156, 236, 0.25); }

.c-radio.c-radio-nofont {
  /* override for radio */
  /* Disable state */
  /* override for radio */ }
  .c-radio.c-radio-nofont span:before {
    content: "";
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -5px;
    border-radius: 500px; }
  .c-radio.c-radio-nofont input[type=radio]:checked + span:before {
    color: #fff;
    opacity: 1;
    transition: color .3s ease-out; }
  .c-radio.c-radio-nofont input[type=radio]:checked + span {
    border-color: #5d9cec;
    background-color: #5d9cec; }
  .c-radio.c-radio-nofont input[type=radio]:checked + span {
    background-color: #fff; }
    .c-radio.c-radio-nofont input[type=radio]:checked + span:before {
      background-color: #5d9cec; }
  .c-radio.c-radio-nofont input[type=radio]:disabled + span {
    border-color: #ddd !important;
    background-color: #ddd !important; }
  .c-radio.c-radio-nofont input[type=radio]:disabled + span {
    background-color: #fff !important; }
    .c-radio.c-radio-nofont input[type=radio]:disabled + span:before {
      background-color: #ddd; }

.switch .form-control {
  padding-top: 7px;
  margin-bottom: 0; }

.switch * {
  cursor: pointer; }

.switch input {
  opacity: 0;
  position: absolute;
  z-index: -1; }

.switch {
  display: flex;
  align-items: center;
  margin: 0; }
  .switch span {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 100px;
    transition: all .5s;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1) inset; }
  .switch span:after {
    content: "";
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 1px solid #ddd;
    border-radius: 400px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    -webkit-transition: all .2s; }
  .switch.switch-lg span {
    width: 50px;
    height: 25px; }
  .switch.switch-lg span:after {
    height: 23px;
    width: 23px; }
  .switch.switch-sm span {
    width: 30px;
    height: 15px; }
  .switch.switch-sm span:after {
    height: 13px;
    width: 13px; }

.switch input:checked + span {
  background-color: #5d9cec;
  border-color: #5d9cec;
  transition: all .5s; }

.switch input:checked + span:after {
  left: 50%;
  transition: all .2s; }

.switch input:disabled + span {
  background-color: #f1f1f1;
  cursor: not-allowed; }

.switch input:focus + span {
  box-shadow: 0 0 0 0.2rem rgba(93, 156, 236, 0.25); }

.note-editor {
  background-image: linear-gradient(#eee 0.1em, transparent 0.1em);
  background-size: 100% 20px;
  background-color: #fff;
  line-height: 20px;
  margin-top: 5px;
  padding: 0;
  padding-bottom: 1px;
  border: none; }
  .note-editor.note-editor-margin {
    background-image: linear-gradient(90deg, transparent 49px, #ffe0c4 49px, #ffe0c4 51px, transparent 51px), linear-gradient(#eee 0.1em, transparent 0.1em);
    padding-left: 55px; }

/* ========================================================================
         Component: utils
 ========================================================================== */
.b0 {
  border-width: 0 !important; }

.bl0 {
  border-left-width: 0 !important; }

.br0 {
  border-right-width: 0 !important; }

.bt0 {
  border-top-width: 0 !important; }

.bb0 {
  border-bottom-width: 0 !important; }

.br, .b, .ba {
  border-right: 1px solid rgba(0, 0, 0, 0.12); }

.bl, .b, .ba {
  border-left: 1px solid rgba(0, 0, 0, 0.12); }

.bt, .b, .ba {
  border-top: 1px solid rgba(0, 0, 0, 0.12); }

.bb, .b, .ba {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.text-primary {
  color: #5d9cec !important; }

.text-success {
  color: #27c24c !important; }

.text-info {
  color: #23b7e5 !important; }

.text-warning {
  color: #ff902b !important; }

.text-danger {
  color: #f05050 !important; }

.text-white {
  color: #fff !important; }

.text-inverse {
  color: #131e26 !important; }

.text-alpha {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-pink {
  color: #f532e5 !important; }

.text-purple {
  color: #7266ba !important; }

.text-dark {
  color: #3a3f51 !important; }

.text-alpha-inverse {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-green {
  color: #37bc9b !important; }

.text-yellow {
  color: #fad732 !important; }

.text-gray-darker {
  color: #232735 !important; }

.text-gray-dark {
  color: #3a3f51 !important; }

.text-gray {
  color: #dde6e9 !important; }

.text-gray-light {
  color: #e4eaec !important; }

.text-gray-lighter {
  color: #edf1f2 !important; }

.text-inherit {
  color: inherit !important; }

.text-sm {
  font-size: 0.74375rem; }

.text-md {
  font-size: 1.6625rem; }

.text-lg {
  font-size: 2.625rem; }

.text-nowrap {
  white-space: nowrap; }

.text-thin {
  font-weight: 100 !important; }

.text-normal {
  font-weight: normal !important; }

.text-bold {
  font-weight: bold !important; }

.inline {
  display: inline-block !important; }

.block-center {
  margin: 0 auto; }

.bg-primary {
  background-color: #5d9cec;
  color: #fff !important; }

.bg-primary-light {
  background-color: #8bb8f1;
  color: #fff !important; }

.bg-primary-dark {
  background-color: #2f80e7;
  color: #fff !important; }

.bg-primary small {
  color: inherit; }

.bg-success {
  background-color: #27c24c;
  color: #fff !important; }

.bg-success-light {
  background-color: #43d967;
  color: #fff !important; }

.bg-success-dark {
  background-color: #1e983b;
  color: #fff !important; }

.bg-success small {
  color: inherit; }

.bg-info {
  background-color: #23b7e5;
  color: #fff !important; }

.bg-info-light {
  background-color: #51c6ea;
  color: #fff !important; }

.bg-info-dark {
  background-color: #1797be;
  color: #fff !important; }

.bg-info small {
  color: inherit; }

.bg-warning {
  background-color: #ff902b;
  color: #fff !important; }

.bg-warning-light {
  background-color: #ffab5e;
  color: #fff !important; }

.bg-warning-dark {
  background-color: #f77600;
  color: #fff !important; }

.bg-warning small {
  color: inherit; }

.bg-danger {
  background-color: #f05050;
  color: #fff !important; }

.bg-danger-light {
  background-color: #f47f7f;
  color: #fff !important; }

.bg-danger-dark {
  background-color: #ec2121;
  color: #fff !important; }

.bg-danger small {
  color: inherit; }

.bg-green {
  background-color: #37bc9b;
  color: #fff !important; }

.bg-green-light {
  background-color: #58ceb1;
  color: #fff !important; }

.bg-green-dark {
  background-color: #2b957a;
  color: #fff !important; }

.bg-green small {
  color: inherit; }

.bg-pink {
  background-color: #f532e5;
  color: #fff !important; }

.bg-pink-light {
  background-color: #f763eb;
  color: #fff !important; }

.bg-pink-dark {
  background-color: #e90bd6;
  color: #fff !important; }

.bg-pink small {
  color: inherit; }

.bg-purple {
  background-color: #7266ba;
  color: #fff !important; }

.bg-purple-light {
  background-color: #9289ca;
  color: #fff !important; }

.bg-purple-dark {
  background-color: #564aa3;
  color: #fff !important; }

.bg-purple small {
  color: inherit; }

.bg-inverse {
  background-color: #131e26;
  color: #fff !important; }

.bg-inverse-light {
  background-color: #243948;
  color: #fff !important; }

.bg-inverse-dark {
  background-color: #020304;
  color: #fff !important; }

.bg-inverse small {
  color: inherit; }

.bg-yellow {
  background-color: #fad732;
  color: #fff !important; }

.bg-yellow-light {
  background-color: #fbe164;
  color: #fff !important; }

.bg-yellow-dark {
  background-color: #f3ca06;
  color: #fff !important; }

.bg-yellow small {
  color: inherit; }

.bg-white {
  background-color: #fff;
  color: inherit !important; }

.bg-gray-darker {
  background-color: #232735;
  color: #fff !important; }

.bg-gray-dark {
  background-color: #3a3f51;
  color: #fff !important; }

.bg-gray {
  background-color: #dde6e9;
  color: #656565 !important; }

.bg-gray-light {
  background-color: #e4eaec;
  color: #656565 !important; }

.bg-gray-lighter {
  background-color: #edf1f2;
  color: #656565 !important; }

.bg-transparent {
  background-color: transparent !important; }

.bg-cover {
  background-size: cover; }

.thumb8 {
  width: 8px !important;
  height: 8px !important; }

.thumb16 {
  width: 16px !important;
  height: 16px !important; }

.thumb24 {
  width: 24px !important;
  height: 24px !important; }

.thumb32 {
  width: 32px !important;
  height: 32px !important; }

.thumb48 {
  width: 48px !important;
  height: 48px !important; }

.thumb64 {
  width: 64px !important;
  height: 64px !important; }

.thumb80 {
  width: 80px !important;
  height: 80px !important; }

.thumb96 {
  width: 96px !important;
  height: 96px !important; }

.thumb128 {
  width: 128px !important;
  height: 128px !important; }

.align-middle {
  vertical-align: middle; }

.align-top {
  vertical-align: top; }

.align-bottom {
  vertical-align: bottom; }

.bg-center {
  background-position: center center;
  background-size: cover; }

.list-icon em {
  font-size: 14px;
  width: 40px;
  vertical-align: middle;
  margin: 0;
  display: inline-block;
  text-align: center;
  transition: all 0.2s;
  line-height: 30px; }
  @media (prefers-reduced-motion: reduce) {
    .list-icon em {
      transition: none; } }

.list-icon div:hover em {
  -webkit-transform: scale(3, 3);
          transform: scale(3, 3); }

.d-table-fixed {
  table-layout: fixed; }

.wd-xxs {
  width: 60px; }

.wd-xs {
  width: 90px; }

.wd-sm {
  width: 150px; }

.wd-sd {
  width: 200px; }

.wd-md {
  width: 240px; }

.wd-lg {
  width: 280px; }

.wd-xl {
  width: 320px; }

.wd-xxl {
  width: 360px; }

.wd-wide {
  width: 100%; }

.wd-auto {
  width: auto; }

.wd-zero {
  width: 0; }

.clickable {
  cursor: pointer; }

.abs-center-container {
  position: relative; }

.abs-center {
  height: 50%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }
  .abs-center.abs-fixed {
    position: fixed;
    z-index: 999; }
  .abs-center.abs-right {
    left: auto;
    right: 20px;
    text-align: right; }
  .abs-center.abs-left {
    right: auto;
    left: 20px;
    text-align: left; }

@media (max-height: 720px) {
  .abs-center {
    position: relative; } }

.link-unstyled {
  text-decoration: none !important;
  outline: none !important; }

.no-resize {
  resize: none;
  max-width: 100%;
  min-width: 100%; }

.ie-fix-flex {
  -ms-flex: 0 0 auto; }

/* ========================================================================
     Component: print
 ========================================================================== */
@media print {
  .sidebar,
  .topnavbar,
  .offsidebar,
  .btn {
    display: none !important;
    width: 0 !important;
    height: 0 !important; }
  .wrapper,
  .wrapper .section-container,
  .content-wrapper {
    margin: 0 !important;
    /* remove margin used for sidebar and expand the content */
    padding: 0 !important;
    width: 100% !important;
    border: 0;
    /* optional: remove top border used to separate the page heading */ }
  .content-wrapper {
    overflow: hidden !important; }
  /* hide sidebar container */
  .aside-container {
    display: none !important; }
  /* remove space used for the sidebar */
  .footer-container {
    margin: 0 !important; } }

/* ========================================================================
   Component: spinner
 ========================================================================== */
body .whirl {
  position: relative; }
  body .whirl:before {
    content: attr(data-spinner-text);
    display: block;
    background-color: #f0f0f0;
    opacity: 0.7; }
  body .whirl.solid {
    opacity: 1; }
  body .whirl:after {
    border-top-color: #5d9cec; }

body .whirl.blade:after {
  border-bottom-color: #5d9cec; }

body .whirl.double-up:after {
  border-right-color: #5d9cec;
  border-top-color: #5d9cec;
  border-left-color: #5d9cec;
  border-bottom-color: #5d9cec; }

body .whirl.duo:after {
  border-right-color: #5d9cec;
  border-left-color: #5d9cec;
  border-top-color: #7266ba;
  border-bottom-color: #7266ba; }

body .whirl.helicopter:after {
  background-color: #5d9cec; }

body .whirl.ringed:after {
  border-right-color: #23b7e5;
  border-top-color: #7266ba;
  border-left-color: #7266ba;
  border-bottom-color: #7266ba; }

body .whirl.traditional:after {
  border-right-color: #23b7e5;
  border-top-color: #23b7e5;
  border-left-color: #7266ba;
  border-bottom-color: #fad732; }

body .whirl.line:after {
  box-shadow: inset -10px 0px 0px rgba(93, 156, 236, 0.5); }

.no-cssanimations body .whirl:before {
  opacity: .9; }

.no-cssanimations body .whirl:after {
  content: "Loading...";
  width: 40px;
  height: 40px;
  margin: -20px 0 -20px;
  line-height: 40px;
  color: #000;
  opacity: 1;
  border: 0;
  box-shadow: 0 0 0 #000;
  background-color: transparent;
  text-align: center; }

body .ball-pulse > div,
body .ball-grid-pulse > div,
body .square-spin > div,
body .ball-pulse-rise > div,
body .ball-rotate > div,
body .ball-rotate > div:before,
body .ball-rotate > div:after,
body .cube-transition > div,
body .ball-zig-zag > div,
body .ball-zig-zag-deflect > div,
body .ball-triangle-path > div,
body .ball-scale > div,
body .line-scale > div,
body .line-scale-party > div,
body .ball-scale-multiple > div,
body .ball-pulse-sync > div,
body .ball-beat > div,
body .line-scale-pulse-out > div,
body .line-scale-pulse-out-rapid > div,
body .ball-spin-fade-loader > div,
body .line-spin-fade-loader > div,
body .triangle-skew-spin > div,
body .ball-grid-beat > div,
body .semi-circle-spin > div {
  background-color: #5d9cec; }

body .ball-clip-rotate-multiple {
  width: 1px; }
  body .ball-clip-rotate-multiple > div {
    border-color: #5d9cec transparent #5d9cec transparent; }
    body .ball-clip-rotate-multiple > div:last-child {
      border-color: transparent #5d9cec transparent #5d9cec; }

body .ball-clip-rotate > div {
  border-color: #5d9cec;
  border-bottom-color: transparent; }

body .ball-clip-rotate-pulse > div:first-child {
  background-color: #5d9cec;
  top: 5px;
  left: -8px; }

body .ball-clip-rotate-pulse > div:last-child {
  border-color: #5d9cec transparent #5d9cec transparent; }

body .square-spin > div,
body .ball-scale-ripple > div,
body .ball-scale-ripple-multiple > div {
  border-color: #5d9cec; }

body .pacman > div:first-of-type {
  border-top-color: #5d9cec;
  border-left-color: #5d9cec;
  border-bottom-color: #5d9cec; }

body .pacman > div:nth-child(2) {
  border-top-color: #5d9cec;
  border-left-color: #5d9cec;
  border-bottom-color: #5d9cec; }

body .pacman > div:nth-child(3),
body .pacman > div:nth-child(4),
body .pacman > div:nth-child(5),
body .pacman > div:nth-child(6) {
  background-color: #5d9cec; }

body .sk-rotating-plane.sk-spinner,
body .sk-double-bounce .sk-double-bounce1,
body .sk-double-bounce .sk-double-bounce2,
body .sk-wave .sk-rect,
body .sk-wandering-cubes .sk-cube1,
body .sk-wandering-cubes .sk-cube2,
body .sk-chasing-dots .sk-dot1,
body .sk-chasing-dots .sk-dot2,
body .sk-three-bounce div,
body .sk-circle .sk-child:before,
body .sk-cube-grid .sk-cube,
body .sk-fading-circle .sk-circle:before,
body .sk-spinner-pulse.sk-spinner,
body .sk-rotating-plane {
  background-color: #5d9cec !important; }

body .sk-wordpress .sk-inner-circle {
  background-color: #fff; }

body .sk-wave .sk-rect {
  float: left;
  margin-right: 1px; }

/* just for loaders.css demo */
.loader-demo {
  height: 150px;
  padding-top: 50px;
  text-align: center; }
  .loader-demo > div {
    display: inline-block; }
  .loader-demo.loader-demo-sk {
    padding-top: 0; }

.topnavbar.color-primary {
  background-color: #f36330 !important;
  background-image: none; }

html {
  font-size: 16px; }

.sidebar-nav .fa,
.sidebar-nav .far,
.sidebar-nav .fas {
  font-size: 1rem; }

.sidebar-nav span {
  font-weight: 600; }

.brand-logo,
.brand-logo-collapsed {
  padding: 0 !important; }

.aside-collapsed .fest-tile {
  display: none; }

.topnavbar .navbar-nav > .nav-item > .nav-link {
  padding: 0rem 0.95rem !important; }

.c-checkbox input[type="checkbox"]:not(:checked) + span {
  background-color: #fff; }

.dataTable thead tr th {
  text-align: center;
  border: 1px solid #eee !important; }

table.dataTable.dataTable {
  border-collapse: collapse !important; }

table.dataTable.dataTable.dataTable {
  margin: 0 !important;
  padding: 0 !important;
  width: auto; }

thead .sorting:before,
thead .sorting:after {
  bottom: 0.35rem !important; }

.emoji-width-fix {
  font-size: initial; }

.emoji-width-fix .fr-emoticon {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block; }

table.dataTable tr > td:nth-child(4) {
  overflow: hidden;
  max-width: 240px; }

