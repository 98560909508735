.Wrapper {
  font-size: 1rem;

  padding: 0.3125rem 1.25rem 0.625rem 1.25rem;
}

.Header {
  font-weight: bold;
}

.Name {
  color: #5d9cec;
}

.Wrapper ul {
  padding-inline-start: 1rem;

  font-weight: bold;

  margin: 0;
}

.ParticipantInfo {
  font-weight: 400;
  font-size: 0.625rem;
}

.Hr {
  height: 1px;
  border-bottom: 1px dotted #cfdbe2;
  margin-top: 0.625rem;
}