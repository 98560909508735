/*!
 *
 * Angle - Bootstrap Admin Template
 *
 * Version: 4.7.5
 * Author: @themicon_co
 * Website: http://themicon.co
 * License: https://wrapbootstrap.com/help/licenses
 *
 */

// Bootstrap
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";
// Variables
@import "app/common/variables";
// Layout
@import "app/layout/layout";
@import "app/layout/layout-extra";
@import "app/layout/layout-animation";
@import "app/layout/top-navbar";
@import "app/layout/sidebar";
@import "app/layout/offsidebar";
@import "app/layout/user-block";
@import "app/layout/settings";
// Common
@import "app/common/page-loader";
@import "app/common/typo";
@import "app/common/bootstrap-reset";
@import "app/common/bootstrap-custom";
@import "app/common/button-extra";
@import "app/common/placeholder";
@import "app/common/cards";
@import "app/common/circles";
@import "app/common/dropdown-extra";
@import "app/common/half-float";
@import "app/common/animate";
@import "app/common/slim-scroll";
@import "app/common/inputs";
@import "app/common/utils";
@import "app/common/print";
@import "app/common/fonts";

@import "app/elements/spinner";

.topnavbar.color-primary {
  background-color: #f36330 !important;

  background-image: none;
}

html {
  font-size: 16px;
}

.sidebar-nav .fa,
.sidebar-nav .far,
.sidebar-nav .fas {
  font-size: 1rem;
}

.sidebar-nav span {
  font-weight: 600;
}

.brand-logo,
.brand-logo-collapsed {
  padding: 0 !important;
}

.aside-collapsed .fest-tile {
  display: none;
}

.topnavbar .navbar-nav > .nav-item > .nav-link {
  padding: 0rem 0.95rem !important;
}

.c-checkbox input[type="checkbox"]:not(:checked) + span {
  background-color: #fff;
}

.dataTable thead tr th {
  text-align: center;
  border: 1px solid #eee !important;
}

table.dataTable.dataTable {
  border-collapse: collapse !important;
}

table.dataTable.dataTable.dataTable {
  margin: 0 !important;
  padding: 0 !important;
  width: auto;
}

thead .sorting:before,
thead .sorting:after {
  bottom: 0.35rem !important;
}

.emoji-width-fix {
  font-size: initial;
}

.emoji-width-fix .fr-emoticon {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
}

table.dataTable tr > td:nth-child(4) {
  overflow: hidden;
  max-width: 240px;
}

