.Pages {
  border: 1px solid #e5e5e5 !important;
}

.Pages:hover {
  cursor: pointer;
}

.Alert {
  width: 100%;

  background-color: #f36330 !important;

  display: flex;
  justify-self: start;
  align-items: center;
}

.ContentWrapper {
  border-top: none !important;
  margin-top: 0 !important;
}
