.Date,
.Month {
  font-weight: 600;
  color: #656565;
}

.Date {
  font-size: 2.25rem;
  line-height: 2.81rem;
}

.Month {
  font-size: 1.25rem;
  line-height: 1.56rem;
}

.Inactive {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.4375rem;
}
