.FestTile {
  margin: 0.75rem 0 0.5rem 0;
  padding: 0 0 1rem 0;

  text-align: center;

  border-bottom: 1px solid #d9d9d9;
}

.FestTile img {
  width: 55%;
}

.FestTile .FestName {
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.4375rem;

  color: #5d9cec;
}

.AsideContainer {
  z-index: 110 !important;
}
